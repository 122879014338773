import { useContext, useEffect, useState, useCallback } from "react";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import axiosAdapter from "../../utils";
import { env } from "../../env";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import styled from "styled-components";
import loadingAnimation from "../../assets/images/Loading Animation.gif";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import BackIcon from "../../assets/images/left_arrow_icon.svg";
import GenericSelection from "../../components/common/_dropDownComponent";
import Repository from "../../assets/images/Repository.svg";
import Branch from "../../assets/images/git-branch.svg";
import Norepository from "../../assets/images/Illustrationnorepo.svg";
import { StyledButtonPrimary } from "../../components/common/_buttonNewOne";
import { ReactComponent as GithubIcon } from "../../assets/images/github_white_background.svg";
import { ReactComponent as GitlabIcon } from "../../assets/images/gitlab_white_background.svg";
import { ReactComponent as BitbucketIcon } from "../../assets/images/bitbucket_white_background.svg";
import { ReactComponent as BackIconSmall } from "../../assets/images/Back_Arrow.svg";
import { ReactComponent as GitConnectedIcon } from "../../assets/images/git_connected.svg";
import { ReactComponent as GitDisconnectedIcon } from "../../assets/images/git_disconnected.svg";

const GithubIconStyled = styled(GithubIcon)`
  width: 2.222rem;
  height: 2.222rem;
  flex: none;
`;

const GitlabIconStyled = styled(GitlabIcon)`
  width: 2.222rem;
  height: 2.222rem;
  flex: none;
`;

const BitbucketIconStyled = styled(BitbucketIcon)`
  width: 2.222rem;
  height: 2.222rem;
  flex: none;
`;

const RightArrowIcon = styled(BackIconSmall)`
  transform: scaleX(-1);
  width: 1.112rem;
  height: 1.112rem;
  flex: none;
`;

const getGitIcon = (provider) => {
  switch (provider) {
    case "github":
      return <GithubIconStyled />;
    case "gitlab":
      return <GitlabIconStyled />;
    case "bitbucket":
      return <BitbucketIconStyled />;
    default:
      return <GithubIconStyled />;
  }
};

const getProviderName = (provider) => {
  switch (provider) {
    case "github":
      return "Github";
    case "gitlab":
      return "Gitlab";
    case "bitbucket":
      return "Bit Bucket";
    default:
      return "Github";
  }
};

const MainContainer = styled.div`
  background-color: #171717;
  height: calc(100vh - 3.9rem);
  width: 100%;
`;

const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #171717;
`;

const AnimationContainer = styled.div`
  position: relative;
  width: 240px;
`;

const ScrollAnimationBar = styled.div`
  width: 100%;
  height: 4px;
  background-color: #2f2f2f;
  border-radius: 2px;
  box-shadow: -8px 4px 9px 0px #2f2f2f;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const LoadingAnimation = styled.img`
  width: 100%;
  bottom: 4px;
`;

const Text = styled.p`
  font-family: Articulat CF Medium;
  color: #f6f6f6;
  font-size: 1.67rem;
  line-height: 1;
  margin: 0;
`;

const Title = styled.div`
  font-family: "Articulat CF Medium";
  color: #f6f6f6;
  font-size: 1.67rem;
  font-weight: bold;
`;

const Content = styled.div`
  font-family: Articulat CF Medium;
  font-size: 1.56rem;
  line-height: 2.167rem;
  color: #cccccc;
  width: 30.34rem;
`;

const VCOptionContainer = styled(Column)`
  cursor: pointer;
  padding: 0.763rem;
  background-color: #2f2f2f;
  border-radius: 0.833rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.069rem solid #2f2f2f;
  :hover {
    border: 0.069rem solid #777777;
  }
`;

const RepoInformation = () => {
  const location = useLocation();
  const history = useHistory();
  const { showMessage } = useContext(SnackbarContext);
  const isRequestFromRestoreApp =
    location.state?.isRequestFromRestoreApp ?? false;

  // States variable
  const [isLoading, setLoading] = useState(true);
  const [allRepos, setAllRepos] = useState([]);
  const [selectedRepo, setSelectedRepo] = useState(null);
  const [allBranches, setAllBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [isGitProviderChosen, setGitProviderChosen] = useState(false);
  const [gitOptions, setGitOptions] = useState(null);
  const [gitData, setGitData] = useState(null);

  const getUsersVcDetails = useCallback(async () => {
    try {
      const response = await axiosAdapter(
        "GET",
        env.REACT_APP_URL + "version-control/getVcAuthDetails",
      );
      setGitOptions(response.data.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log("Error: getVcAuthDetails", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage(
          "Failed to obtain current status of connected GIT Providers",
        );
      }
    }
  }, [showMessage]);

  const getAllRepo = useCallback(
    async (vcProvider) => {
      setLoading(true);
      try {
        const stringifiedData = {
          vcProvider,
        };
        let response = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "version-control/getAllRepos",
          stringifiedData,
        );
        setAllRepos(response.data.data);
        setLoading(false);
      } catch (err) {
        console.log("Error: getAllRepos", err);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage("Unable to get the repository list");
        }
        setLoading(false);
      }
    },
    [showMessage],
  );

  const getAllbranchesOfRepo = useCallback(
    async (selectedRepo) => {
      setLoading(true);
      try {
        const stringifiedData = {
          vcProvider: gitData.provider,
          repoId: selectedRepo.repoId,
          repoName: selectedRepo.repoName,
          workspaceName: selectedRepo?.workspaceName,
        };
        let getBranchesResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "version-control/getAllBranchesOfRepo",
          stringifiedData,
        );
        setAllBranches(getBranchesResponse.data.data);
        setLoading(false);
      } catch (err) {
        if (err.response.data.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage("Unable to get the branch information");
        }
        setLoading(false);
      }
    },
    [gitData, showMessage],
  );

  const handleSelectRepo = useCallback(
    (item) => {
      setSelectedRepo(item);
      setSelectedBranch(null);
      getAllbranchesOfRepo(item);
    },
    [getAllbranchesOfRepo],
  );

  const handleSelectBranch = useCallback((item) => {
    setSelectedBranch(item);
  }, []);

  const handleNextClick = async () => {
    setLoading(true);
    try {
      let payload = {
        applicationId: location.state?.applicationId,
        vcProvider: gitData.provider,
        username: gitData.username,
        repoName: selectedRepo.repoName,
        branchName: selectedBranch.branchName,
        worksapceName: selectedRepo.workspaceName,
      };
      if (!isRequestFromRestoreApp) {
        await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "debug/cloneRepo",
          payload,
        );
      }
      setLoading(false);

      let appDetailsResponse = "";
      if (isRequestFromRestoreApp) {
        const workspaceId = parseInt(
          localStorage.getItem("selectedWorkspaceId") || 0,
        );
        if (!workspaceId) {
          history.push("/");
        }
        appDetailsResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/getApplicationData",
          {
            applicationId: location.state?.applicationId,
            workspaceId,
          },
        );
      }

      history.push("/codeEditor", {
        applicationId: location.state?.applicationId,
        isRedirected: true,
        gitUsername: gitData.username,
        repositoryName: selectedRepo.repoName,
        authProvider: gitData.provider,
        isRequestFromRestoreApp: isRequestFromRestoreApp,
        ...(isRequestFromRestoreApp && {
          imageName: appDetailsResponse.data?.data?.imageName,
          tag: appDetailsResponse.data?.data?.tag,
          icon_file: appDetailsResponse.data?.data?.icon_file,
          applicationName: appDetailsResponse.data?.data?.applicationName,
          applicationVersion: appDetailsResponse.data?.data?.applicationVersion,
          description: appDetailsResponse.data?.data?.description,
          isIngressRequired: appDetailsResponse.data?.data?.isIngressRequired,
          userConfigs: appDetailsResponse.data?.data?.userConfigs,
        }),
      });
    } catch (err) {
      console.log("Error: cloneRepo", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Unable to clone the repository in the container !!!");
      }
      setLoading(false);
    }
  };

  // On Mount of this page, call the API to get the status of the Application
  useEffect(() => {
    const workspaceId = parseInt(
      localStorage.getItem("selectedWorkspaceId") || 0,
    );
    if (!workspaceId) {
      history.push("/");
    }

    (async () => {
      try {
        if (!workspaceId || !location.state?.applicationId) {
          return;
        }
        let appDetailsResponse = await axiosAdapter(
          "POST",
          env.REACT_APP_URL + "pipeline/getApplicationData",
          {
            applicationId: location.state?.applicationId,
            workspaceId,
          },
        );
        if (appDetailsResponse.data?.data?.applicationStatus) {
          if (appDetailsResponse.data?.data?.applicationStatus === 1) {
            showMessage("POD for the application is not deployed yet");
            throw new Error("POD for the application is not deployed yet");
          } else if (appDetailsResponse.data?.data?.applicationStatus === 2) {
            getUsersVcDetails();
          } else {
            if (isRequestFromRestoreApp) {
              // getUsersVcDetails();
              setLoading(false);

              let gitData = {
                provider: location.state?.vcProvider,
                username: location.state?.username,
                avatarUrl: location.state?.avatarUrl,
              };
              let gitOptions = { providerName: gitData };
              let selectedObj = {
                repoId: 902070128,
                repoName: location.state?.repositoryName,
              };
              let selectedBranchObj = {
                branchId: 1,
                branchName: location.state?.branchName,
              };
              setGitOptions(gitOptions);
              setGitData(gitData);
              setGitProviderChosen(true);
              setAllRepos([selectedObj]);
              setSelectedRepo(selectedObj);
              setAllBranches([selectedBranchObj]);
              setSelectedBranch(selectedBranchObj);
            } else {
              history.push("/codeEditor", {
                applicationId: location.state?.applicationId,
                isRedirected: true,
                isIngressRequired:
                  appDetailsResponse.data?.data?.isIngressRequired,
                gitUsername: appDetailsResponse.data?.data?.gitUsername,
                ...appDetailsResponse.data?.data,
                isRequestFromRestoreApp: isRequestFromRestoreApp,
                ...(isRequestFromRestoreApp && {
                  imageName: appDetailsResponse.data?.data?.imageName,
                  tag: appDetailsResponse.data?.data?.tag,
                  icon_file: appDetailsResponse.data?.data?.icon_file,
                  applicationName:
                    appDetailsResponse.data?.data?.applicationName,
                  applicationVersion:
                    appDetailsResponse.data?.data?.applicationVersion,
                  description: appDetailsResponse.data?.data?.description,
                  isIngressRequired:
                    appDetailsResponse.data?.data?.isIngressRequired,
                  userConfigs: appDetailsResponse.data?.data?.userConfigs,
                }),
              });
            }
          }
        } else {
          showMessage("Unable to obtain the application status in response");
          throw new Error(
            "Unable to obtain the application status in response",
          );
        }
      } catch (err) {
        console.log("Error: getApplicationData", err);
        if (err.response?.data?.remarks) {
          showMessage(err.response.data.remarks);
        } else {
          showMessage(
            err.message || "Unable to fetch the Application Details !!!",
          );
        }
        setLoading(false);
        setTimeout(() => {
          history.push("/applications");
        }, 1500);
      }
    })();
  }, [getUsersVcDetails, history, location, showMessage]);

  // Is the page is not opened through redirection internally, then redirect to home
  if (!location.state?.isRedirected) return history.push("/");

  return isLoading ? (
    <ParentContainer>
      <AnimationContainer>
        <ScrollAnimationBar />
        <LoadingAnimation src={loadingAnimation} alt="Loading Animation" />
      </AnimationContainer>
    </ParentContainer>
  ) : (
    <MainContainer>
      <CustomRow alignItems="normal" style={{ overflow: "auto" }}>
        <Column
          xs={12}
          style={{
            padding: "1.111rem 1.112rem",
            display: "flex",
            gap: "1.112rem",
            alignItems: "center",
            justifyContent: "flex-start",
            borderBottom: "0.1rem solid #2f2f2f",
          }}
        >
          <button
            type="button"
            style={{
              background: "none",
              border: "none",
              padding: 0,
              cursor: "pointer",
              height: "2.222rem",
              width: "2.222rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            aria-label="Go Back"
            onClick={() => history.push("/applications")}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                history.push("/applications");
              }
            }}
          >
            <img
              src={BackIcon}
              alt=""
              style={{ height: "2.222rem", width: "2.222rem" }}
            />
          </button>
          <Text>
            {isRequestFromRestoreApp
              ? "Select Repository and Branch to build and restore your application"
              : "Choose Repository and Branch to continue"}
          </Text>
        </Column>

        <Column xs={4} style={{ borderRight: "0.06rem solid #2f2f2f" }}>
          <CustomRow>
            {!isGitProviderChosen && gitOptions && (
              <Column
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: "1.111rem",
                  flexWrap: "wrap",
                  padding: "1.111rem",
                }}
              >
                <Column>
                  <Text
                    style={{
                      fontSize: "1.388rem",
                      lineHeight: "1.5rem",
                      fontWeight: "bold",
                    }}
                  >
                    Connect or choose your GIT Provider
                  </Text>
                  <Text
                    style={{
                      fontFamily: "Articulat CF Medium",
                      fontSize: "1.13rem",
                      color: "#777777",
                      paddingTop: "1.112rem ",
                      paddingBottom: "0.7rem",
                      lineHeight: "1.4rem",
                      paddingRight: "0",
                      letterSpacing: "0.5px",
                    }}
                  >
                    You are currently not connected to any code repository.
                    Please connect a Code Repository to continue creating your
                    new application.
                  </Text>
                </Column>
                {Object.keys(gitOptions).map((key) => (
                  <VCOptionContainer
                    key={key}
                    onClick={() => {
                      if (gitOptions[key]) {
                        setGitData(gitOptions[key]);
                        setGitProviderChosen(true);
                        getAllRepo(key);
                      } else {
                        history.push({
                          pathname: "/settings",
                          search: `?section=codeRespository`,
                        });
                      }
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: "1.111rem",
                      }}
                    >
                      {getGitIcon(key)}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.34rem",
                        }}
                      >
                        <Text
                          style={{
                            fontSize: "1.111rem",
                            lineHeight: "1",
                            fontWeight: "bold",
                            color: "#777777",
                          }}
                        >
                          {getProviderName(key)}
                        </Text>
                        <Text
                          style={{
                            fontSize: "0.833rem",
                            lineHeight: "1",
                            display: "flex",
                            alignItems: "center",
                            gap: "0.277rem",
                            ...(gitOptions[key]
                              ? { color: "#f6f6f6" }
                              : { color: "#EA3A3A" }),
                          }}
                        >
                          {gitOptions[key] ? (
                            <GitConnectedIcon />
                          ) : (
                            <GitDisconnectedIcon />
                          )}
                          {gitOptions[key]
                            ? gitOptions[key].username
                            : "Not Connected"}
                        </Text>
                      </div>
                    </div>
                    <RightArrowIcon />
                  </VCOptionContainer>
                ))}
              </Column>
            )}
            {gitData && isGitProviderChosen && (
              <Column
                xs={12}
                style={{
                  padding: "1.112rem",
                  borderBottom: "0.06rem solid #2f2f2f",
                }}
              >
                <CustomRow>
                  <Column xs={2}>
                    <img
                      src={gitData.avatarUrl}
                      alt="Avatar"
                      style={{
                        height: "3.33rem",
                        width: "3.33rem",
                        borderRadius: "0.55rem",
                      }}
                    />
                  </Column>
                  <Column
                    xs={10}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        lineHeight: "1.95rem",
                      }}
                    >
                      {gitData.provider.charAt(0).toUpperCase() +
                        gitData.provider.slice(1)}
                    </Text>
                    <Text
                      style={{
                        color: "#B0B0B0",
                        fontWeight: "normal",
                        fontSize: "0.97rem",
                        lineHeight: "1",
                        paddingTop: "0.138rem",
                      }}
                    >
                      {gitData.username}
                    </Text>
                  </Column>
                  {!isRequestFromRestoreApp && (
                    <Column style={{ marginTop: "1.111rem" }}>
                      <Text
                        style={{
                          fontSize: "0.833rem",
                          lineHeight: "1",
                          color: "#777777",
                        }}
                      >
                        {`Got your repository somewhere else? `}
                        <button
                          type="button"
                          style={{
                            background: "none",
                            border: "none",
                            padding: 0,
                            cursor: "pointer",
                            textDecoration: "underline",
                            color: "#F6F6F6",
                          }}
                          aria-label="Switch repository provider"
                          onClick={() => {
                            setGitData(null);
                            setGitProviderChosen(false);
                            setSelectedRepo(null);
                            setSelectedBranch(null);
                            setAllRepos([]);
                            setAllBranches([]);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              setGitData(null);
                              setGitProviderChosen(false);
                              setSelectedRepo(null);
                              setSelectedBranch(null);
                              setAllRepos([]);
                              setAllBranches([]);
                            }
                          }}
                        >
                          Click here to switch
                        </button>
                      </Text>
                    </Column>
                  )}
                </CustomRow>
              </Column>
            )}
            {isGitProviderChosen && gitData && (
              <Column xs={12} style={{ padding: "0 1.111rem" }}>
                <GenericSelection
                  icon={Repository}
                  dropdownItems={allRepos}
                  selectedItem={selectedRepo}
                  title="Select Repository"
                  placeholder="Search for Repository"
                  onSelect={handleSelectRepo}
                  disable={isRequestFromRestoreApp}
                  nameFunction={(item) => item.repoName}
                  filterFunction={(arr, text) =>
                    arr.filter((item) =>
                      item.repoName.toLowerCase().includes(text.toLowerCase()),
                    )
                  }
                  noMargin
                />
              </Column>
            )}
            {isGitProviderChosen && gitData && (
              <Column
                xs={12}
                style={{
                  padding: "0 1.112rem",
                  marginBottom: "1.667rem",
                  marginTop: "0.3rem",
                }}
              >
                <GenericSelection
                  icon={Branch}
                  dropdownItems={allBranches}
                  selectedItem={selectedBranch}
                  title="Select Branch"
                  placeholder="Search for Branch"
                  onSelect={handleSelectBranch}
                  nameFunction={(item) => item.branchName}
                  filterFunction={(arr, text) =>
                    arr.filter((item) =>
                      item.branchName
                        .toLowerCase()
                        .includes(text.toLowerCase()),
                    )
                  }
                  disable={
                    !allBranches ||
                    allBranches.length === 0 ||
                    isRequestFromRestoreApp
                  }
                  noMargin
                />
              </Column>
            )}
            {isGitProviderChosen && gitData && (
              <Column xs={12} style={{ padding: "0 1.112rem" }}>
                <StyledButtonPrimary
                  disabled={
                    !(
                      location.state?.applicationId &&
                      selectedRepo &&
                      selectedBranch
                    )
                  }
                  style={{ width: "100%" }}
                  onClick={handleNextClick}
                >
                  Next
                </StyledButtonPrimary>
              </Column>
            )}
            {!isGitProviderChosen && !gitOptions && (
              <Column xs={12}>
                <Text
                  style={{
                    fontFamily: "Articulat CF Medium",
                    fontSize: "1.112rem",
                    color: "#777777",
                    padding: "1.111rem",
                  }}
                >
                  Failed to obtain the Version Control options, please try again
                  later
                </Text>
              </Column>
            )}
          </CustomRow>
        </Column>
        <Column xs={12} md={8}>
          <div
            style={{
              height: "100%",
              width: "100%",
              padding: "1.67rem 2.225rem 1.67rem 1.67rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Title style={{ fontSize: "3.33rem", lineHeight: "4.33rem" }}>
              {" "}
              Get Started...
            </Title>
            <Content style={{ paddingTop: "1.112rem", letterSpacing: "0.5px" }}>
              Select Repository and Branch to build and publish your first
              custom application!
            </Content>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "1.875rem",
              }}
            >
              <img
                style={{ height: "27.013rem", width: "31.458rem" }}
                src={Norepository}
                alt="no repo"
              />
            </div>
          </div>
        </Column>
      </CustomRow>
    </MainContainer>
  );
};

export default RepoInformation;
