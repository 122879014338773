import React from "react";
import GenericModal from "./_genericModal";
import styled from "styled-components";
import { StyledButtonsecondary, StyledDeleteButton } from "./_buttonNewOne";
import PropTypes from "prop-types";

const Title = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 1.67rem;
  font-weight: bold;
  line-height: 2.88rem;
  color: #f6f6f6;
`;

const Message = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 1.25rem;
  line-height: 1.56rem;
  padding-top: 1.67rem;
  color: #ececec;
  letter-spacing: 0.5px;
  opacity: 70%;
`;

const ButtonContainer = styled.div`
  display: flex;
  padding-top: 5.8rem;
  justify-content: flex-end;
  gap: 1.12rem;
`;

const GenericDeleteModal = ({
  isModalOpen,
  onClose,
  title,
  message,
  deleteButtonText,
  onDelete,
}) => {
  return (
    console.log(
      "insidedeletemodalcomponent",
      title,
      deleteButtonText,
      message,
      onDelete,
    ),
    (
      <GenericModal show={isModalOpen} onClose={onClose}>
        <Title>{title}</Title>
        <Message>{message}</Message>
        <ButtonContainer>
          <StyledButtonsecondary
            style={{ width: "14.167rem" }}
            onClick={onClose}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledDeleteButton style={{ width: "14.167rem" }} onClick={onDelete}>
            {deleteButtonText}
          </StyledDeleteButton>
        </ButtonContainer>
      </GenericModal>
    )
  );
};
GenericDeleteModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  deleteButtonText: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default GenericDeleteModal;
