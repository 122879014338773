import styled from "styled-components";
import GenericModal from "../../components/common/_genericModal";
import React, { useEffect, useState, useContext } from "react";
import axiosAdapter from "../../utils";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import { env } from "../../env";
import CustomRow from "../../components/common/_customRow";
import Column from "../../components/common/_customColumn";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../../components/common/_buttonNewOne";
import PropTypes from "prop-types"; // Import PropTypes
import DynamicStepper from "../../pages/WorkSpaceConnectors/DynamicStepper";
import { ReactComponent as StepperUserIcon } from "../../assets/images/Stepper-user.svg";
import { ReactComponent as StepperGithubIcon } from "../../assets/images/Stepper-github.svg";
import { ReactComponent as StepperBranchIcon } from "../../assets/images/Stepper-branch.svg";
import { ReactComponent as StepperBitBucketIcon } from "../../assets/images/Stepper-bit-bucket.svg";
import { ReactComponent as StepperGitlabIcon } from "../../assets/images/Stepper-gitlab.svg";

import { useHistory } from "react-router-dom";

const Text = styled.p`
  font-family: Articulat CF Medium;
  margin: 0;
`;

const RestoreApplicationModal = ({
  isOpen,
  onClose,
  customConnectorName,
  customConnectorId,
  isRestorable,
  vcProvider,
}) => {
  const { showMessage } = useContext(SnackbarContext);
  const [isLoading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [animatingLine, setAnimatingLine] = useState(null);
  const [contentText, setContentText] = useState(
    "Please wait while we check if everything is in place for you to restore your application, this will only take a few seconds.",
  );
  const getStepIconAndStatus = (vcProvider) => {
    let icon;
    let statusText;

    if (vcProvider === "github") {
      icon = <StepperGithubIcon />;
      statusText = "Github Connection";
    } else if (vcProvider === "gitlab") {
      icon = <StepperGitlabIcon />;
      statusText = "Gitlab Connection";
    } else if (vcProvider === "bitbucket") {
      icon = <StepperBitBucketIcon />;
      statusText = "Bitbucket Connection";
    } else {
      icon = <StepperBitBucketIcon />;
      statusText = "Bitbucket Connection";
    }

    return { icon, statusText };
  };

  const { icon, statusText } = getStepIconAndStatus(vcProvider);

  const [steps, setSteps] = useState([
    {
      id: 1,
      name: "Step 1",
      icon: <StepperUserIcon />,
      active: "Pending",
      statusText: "User Permissions",
    },
    {
      id: 2,
      name: "Step 2",
      icon: icon,
      active: "Pending",
      statusText: statusText,
    },
    {
      id: 3,
      name: "Step 3",
      icon: <StepperBranchIcon />,
      active: "Pending",
      statusText: "Repo & Branch Access",
    },
  ]);
  const [completedSteps, setCompletedSteps] = useState(0);
  const [shouldStop, setShouldStop] = useState(false);
  const history = useHistory();
  const [isPermissionConfirmationOpen, setPermissionConfirmationOpen] =
    useState(false);

  const fetchConnectorVersions = async () => {
    setLoading(true);
    setCompletedSteps(0);
    let completedStepsTemp = 0;
    let shouldStopVal = false;
    try {
      for (let i = 0; i < steps.length; i++) {
        let isActive = false;
        let res = false;
        let resVal = "";
        if (!shouldStopVal) {
          if (i === 0) {
            res = isRestorable === true;
          } else if (i === 1) {
            try {
              let response = await axiosAdapter(
                "GET",
                env.REACT_APP_URL + "version-control/getVcAuthDetails",
              );
              let gitObjects = response?.data?.data[vcProvider]?.provider;
              res = gitObjects;
            } catch (error) {}
          } else {
            try {
              let selectedWorkspaceId = localStorage.getItem(
                "selectedWorkspaceId",
              );
              let payloadObj = {
                customConnectorId: parseInt(customConnectorId),
                workspaceId: parseInt(selectedWorkspaceId || 0),
              };
              let response = await axiosAdapter(
                "POST",
                env.REACT_APP_URL +
                  "pipeline/checkUserHasRepoAndBranchAccessForCustomConnector",
                payloadObj,
              );
              res = response?.data?.code === 200;
            } catch (error) {
              resVal = error.response.data.remarks;
            }
          }
          if (res) {
            isActive = true;
            setCompletedSteps((prev) => prev + 1);
            completedStepsTemp = completedStepsTemp + 1;
            setAnimatingLine(i + 1); // Animate the stepper line
            setCurrentStep((prev) => prev + 1);
          } else {
            shouldStopVal = true;
            setShouldStop(true);
            setContentText(() => {
              switch (completedStepsTemp) {
                case 0:
                  return "You cannot restore this custom connector due to insufficient permissions. Please contact your admin to upgrade your role.";
                case 1: {
                  let type =
                    vcProvider === "github"
                      ? "Github"
                      : vcProvider === "gitlab"
                        ? "Gitlab"
                        : "Bitbucket";
                  return `You are not currently connected to ${type} required to restore the application for this custom connector. Please connect to ${type} to proceed.`;
                }
                case 2: {
                  console.log(resVal, "keee");
                  let showText = "";
                  if (resVal === "You do not have access to this repository") {
                    showText =
                      "You cannot restore the application for this custom connector because the repository has been deleted. Please contact your administrator for assistance.";
                  } else if (
                    resVal === "You do not have access to this branch"
                  ) {
                    showText =
                      "You cannot restore the application for this custom connector because the branch has been deleted. Please contact your administrator for assistance.";
                  } else {
                    showText =
                      "You cannot restore the application. Please contact your administrator for assistance.";
                  }
                  return showText;
                }
                default:
                  return "Please wait while we check if everything is in place for you to restore your application, this will only take a few seconds.";
              }
            });
          }

          setSteps((prevSteps) =>
            prevSteps.map((step) => {
              if (step.id === i + 1) {
                // Find the step by ID
                let color = isActive ? "#00BCD4" : "#EA3A3A";

                let eleId = "";
                if (step.id === 3) {
                  eleId = "StepperBranchIcon";
                } else if (step.id === 2) {
                  eleId =
                    vcProvider === "github"
                      ? "StepperGithubIcon"
                      : vcProvider === "gitlab"
                        ? "StepperGitlabIcon"
                        : "StepperBitBucketIcon";
                } else {
                  eleId = "StepperUserIcon";
                }

                let rectElement = document.getElementById(eleId);
                if (rectElement) {
                  rectElement.setAttribute("fill", color);
                }

                return {
                  ...step,
                  active: isActive,
                };
              }
              return step; // Return other steps unchanged
            }),
          );
        }
      }
    } catch (err) {
      console.error("Error in fetchConnectorVersions:", err);
      showMessage(
        err.response?.data?.remarks || "Failed to fetch connector versions",
        "error",
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchConnectorVersions();
  }, [customConnectorId, showMessage, steps.length]);

  const handleClickSubmit = async () => {
    if (shouldStop && completedSteps <= 1) {
      //go to settings
      history.push({
        pathname: "/settings",
        search: `?section=codeRespository`,
      });
    } else if (isPermissionConfirmationOpen) {
      onClose();
    } else {
      let res = false;
      try {
        let selectedWorkspaceId = localStorage.getItem("selectedWorkspaceId");
        let payloadObj = {
          customConnectorId: parseInt(customConnectorId),
          workspaceId: parseInt(selectedWorkspaceId || 0),
        };
        let response = await axiosAdapter(
          "POST",
          env.REACT_APP_URL +
            "pipeline/checkApplicationBeingRestoredForCustomConnector",
          payloadObj,
        );
        res = response?.data?.code === 200;
      } catch (error) {}
      if (res) {
        //go to next step
        history.push("/createApplication", {
          isRequestFromRestoreApp: true,
          applicationName: customConnectorName,
          customConnectorId: customConnectorId,
          vcProvider: vcProvider,
        });
      } else {
        setPermissionConfirmationOpen(true);
      }
    }
  };
  return (
    <>
      <GenericModal
        show={isOpen}
        onClose={onClose}
        title={
          isPermissionConfirmationOpen
            ? `Unable to restore this ${customConnectorName}`
            : `Restore application for ${customConnectorName}`
        }
        style={{ padding: "2.1rem" }}
      >
        {!isPermissionConfirmationOpen && (
          <DynamicStepper
            steps={steps}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            animatingLine={animatingLine}
          />
        )}
        <CustomRow style={{ marginTop: "1.3rem" }}>
          <Column style={{ marginBottom: "1.39rem" }}>
            <Text
              style={{
                opacity: 0.7,
                color: "#ECECEC",
                fontSize: "1.25rem",
                lineHeight: "1.6rem",
                letterSpacing: "0px",
                marginBottom: isPermissionConfirmationOpen ? "5rem" : "",
              }}
            >
              {isPermissionConfirmationOpen
                ? `You currently cannot restore this application because it’s already being restored by another user. Please try again later.`
                : contentText}
            </Text>
          </Column>
          <Column
            xs={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <StyledButtonsecondary
              style={{ marginRight: "1.112rem", width: "14.167rem" }}
              onClick={onClose}
              disabled={isLoading}
            >
              Cancel
            </StyledButtonsecondary>
            <StyledButtonPrimary
              style={{
                width: "14.167rem",
                marginRight: "1px",
              }}
              disabled={
                isLoading ||
                (shouldStop &&
                  completedSteps !== 1 &&
                  completedSteps !== steps.length)
              }
              onClick={handleClickSubmit}
            >
              {shouldStop && completedSteps === 1
                ? "Go to Settings"
                : isPermissionConfirmationOpen
                  ? "Go Back"
                  : "Restore Application"}
            </StyledButtonPrimary>
          </Column>
        </CustomRow>
      </GenericModal>

      {/* <PermissionModal
        dialogTitle={`Restore application for ${customConnectorName}`}
        contentText={`Please wait while we check if everything is in place for you to restore your application, this will only take a few seconds`}
        onDialogClose={() => setPermissionConfirmationOpen(false)}
        showDeleteDialog={isPermissionConfirmationOpen}
        btnText={`Restore Application`}
        isPermissionDisabled={false}
        onDeleteClick={() => {
          setPermissionConfirmationOpen(false);
        }}
      /> */}
    </>
  );
};

RestoreApplicationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  customConnectorName: PropTypes.string.isRequired,
  customConnectorId: PropTypes.string.isRequired,
  isRestorable: PropTypes.bool.isRequired,
  vcProvider: PropTypes.string.isRequired,
};

export default RestoreApplicationModal;
