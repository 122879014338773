import React from "react";
import Calendar from "react-calendar";
import styled from "styled-components";
import "react-calendar/dist/Calendar.css";
import PropTypes from "prop-types";

const StyledCalendar = styled(Calendar)`
  width: 22.22rem;
  background-color: #2f2f2f;
  border: none;
  border-radius: 0.7rem;
  color: #fafafa;

  .react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    background-color: #2f2f2f;
    border-bottom: 1px solid #444;
    font-size: 0.972rem;
    height: 1.8rem;
    padding-bottom: 1rem;
  }

  .react-calendar__navigation__label,
  .react-calendar__navigation__arrow {
    background-color: transparent !important;
    color: #fafafa;

    &:hover {
      background-color: transparent !important;
      cursor: pointer !important;
    }
    &:active {
      background-color: transparent !important;
      cursor: pointer !important;
    }
  }

  .react-calendar__tile {
    background-color: transparent;
    color: #fafafa;
    padding-right: 0;
    padding-left: 0;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
    line-height: 2.2rem;
    font-size: 0.972rem;
    border-radius: 50%;
    text-align: center;
    max-width: 15%;

    &:hover {
      background-color: #444;
      cursor: pointer;
    }

    &:enabled:focus {
      background-color: #444;
    }

    &[class*="react-calendar__tile--active"] {
      background-color: #47ccd6;
      color: #fff;
      border-radius: 50%;
    }

    &:disabled {
      color: #777 !important;
      background-color: transparent !important;
    }
  }

  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-size: 0.75rem;
    color: #b0b0b0;
    background-color: #2f2f2f;
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0;
    abbr {
      text-decoration: none;
    }
  }

  /* .react-calendar__month-view__days__day--weekend {
    color: #b0b0b0;
  } */
`;

function CustomCalendar({ onDateSelect, setSelectedDate, selectedDate }) {
  const handleDateChange = (newDate) => {
    setSelectedDate(newDate);
    onDateSelect(newDate);
  };

  const isPastDate = (date) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return date < today;
  };

  return (
    <div>
      <StyledCalendar
        onChange={handleDateChange}
        value={selectedDate}
        locale="en-US"
        tileDisabled={({ date }) => isPastDate(date)}
        maxDetail="month"
        minDetail="month"
      />
    </div>
  );
}

CustomCalendar.propTypes = {
  onDateSelect: PropTypes.func.isRequired,
  setSelectedDate: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
};

export default CustomCalendar;
