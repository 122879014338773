import styled from "styled-components";
import GenericModal from "../../../components/common/_genericModal";
import CustomRow from "../../../components/common/_customRow";
import Column from "../../../components/common/_customColumn";
import {
  StyledButtonsecondary,
  StyledButtonPrimary,
} from "../../../components/common/_buttonNewOne";
import PropTypes from "prop-types";

const Text = styled.p`
  font-family: "Articulat CF Medium";
  color: #ececec;
  font-size: 1.25rem;
  margin: 0;
  opacity: 0.7;
  letter-spacing: 0px;
  margin-bottom: 3rem;
`;
const PermissionModal = ({
  showDeleteDialog,
  onDialogClose,
  dialogTitle,
  onDeleteClick,
  contentText,
  btnText,
  isPermissionDisabled,
}) => {
  return (
    <GenericModal
      title={dialogTitle}
      show={showDeleteDialog}
      onClose={onDialogClose}
    >
      <CustomRow>
        <Column style={{ marginBottom: "2.77rem" }}>
          <Text>{contentText}</Text>
        </Column>
        <Column style={{ display: "flex", justifyContent: "flex-end" }}>
          <StyledButtonsecondary
            style={{ width: "14.167rem", marginRight: "1.112rem" }}
            onClick={onDialogClose}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledButtonPrimary
            style={{ width: "14.167rem" }}
            onClick={onDeleteClick}
            disabled={isPermissionDisabled}
          >
            {btnText}
          </StyledButtonPrimary>
        </Column>
      </CustomRow>
    </GenericModal>
  );
};

PermissionModal.propTypes = {
  showDeleteDialog: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  contentText: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
  isPermissionDisabled: PropTypes.bool.isRequired,
};

export default PermissionModal;
