import styled from "styled-components";
import GenericModal from "../../../components/common/_genericModal";
import CustomRow from "../../../components/common/_customRow";
import Column from "../../../components/common/_customColumn";
import {
  StyledButtonsecondary,
  StyledPrimaryButton2,
} from "../../../components/common/_buttonNewOne";
import PropTypes from "prop-types";

const Text = styled.p`
  font-family: "Articulat CF Medium";
  color: #ececec;
  font-size: 1.22rem;
  margin: 0;
  opacity: 0.7;
  letter-spacing: 0px;
  font-weight: 100 !important;
`;
const ApplicationDeleteConfirmationDialog = ({
  showDeleteDialog,
  onDialogClose,
  dialogTitle,
  onDeleteClick,
}) => {
  return (
    <GenericModal
      title={dialogTitle}
      show={showDeleteDialog}
      onClose={onDialogClose}
    >
      <CustomRow>
        <Column style={{ marginBottom: "2.77rem" }}>
          <Text>
            If you delete this application, you won't be able to retrieve it.
            You would have to add the application again.
          </Text>
        </Column>
        <Column style={{ display: "flex", justifyContent: "flex-end" }}>
          <StyledButtonsecondary
            style={{ width: "14.167rem", marginRight: "1.112rem" }}
            onClick={onDialogClose}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledPrimaryButton2
            style={{ width: "14.167rem" }}
            onClick={onDeleteClick}
          >
            Delete Application
          </StyledPrimaryButton2>
        </Column>
      </CustomRow>
    </GenericModal>
  );
};

ApplicationDeleteConfirmationDialog.propTypes = {
  showDeleteDialog: PropTypes.bool.isRequired,
  onDialogClose: PropTypes.func.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default ApplicationDeleteConfirmationDialog;
