import { useContext, useState } from "react";
import CustomRow from "../../../components/common/_customRow";
import GenericModal from "../../../components/common/_genericModal";
import Column from "../../../components/common/_customColumn";
import styled from "styled-components";
import { ReactComponent as UserIcon } from "../../../assets/images/user_icon.svg";
import { ReactComponent as AdminIcon } from "../../../assets/images/admin_icon.svg";
import { ReactComponent as WarningIcon } from "../../../assets/images/warning_yellow_icon.svg";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../../../components/common/_buttonNewOne";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";
import axiosAdapter from "../../../utils";
import { env } from "../../../env";
import PropTypes from "prop-types";

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const StyledAdminIcon = styled(AdminIcon)`
  height: 1.666rem;
  width: 1.666rem;
  & rect {
    fill: ${({ $isSelected }) => ($isSelected ? "#47CCD6" : "#4B4B4B")};
  }
  & path {
    fill: ${({ $isSelected }) => ($isSelected ? "#2f2f2f" : "#F6F6F6")};
  }
`;

const StyledUserIcon = styled(UserIcon)`
  height: 1.666rem;
  width: 1.666rem;
  & rect {
    fill: ${({ $isSelected }) => ($isSelected ? "#47CCD6" : "#4B4B4B")};
  }
  & path {
    fill: ${({ $isSelected }) => ($isSelected ? "#2f2f2f" : "#F6F6F6")};
  }
`;

const StyledWarningIcon = styled(WarningIcon)`
  height: 1.111rem;
  width: 1.111rem;
`;

const ColumnWithFlexWrap = styled(CustomColumn)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.222rem;
`;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  font-size: 1.2rem;
  line-height: 1.555rem;
  margin: 0;
`;

const InputField = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 1.111rem;
  line-height: 1.944rem;
  font-weight: 500;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  caret-color: white;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 0.625rem 1.111rem;
  width: 100%;
  border-radius: 0.555rem;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.069rem solid #777777;
`;

const SelectionContainer = styled.div`
  padding: 1.111rem;
  width: 100%;
  border-radius: 0.555rem;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.111rem;
  border: 0.069rem solid #777777;
  cursor: pointer;
`;

const UserAndAdminContainer = ({
  isSelected,
  name,
  handleClick,
  description,
  IconComponent,
}) => {
  return (
    <SelectionContainer
      onClick={handleClick}
      style={{
        ...(isSelected && {
          borderColor: "#47CCD6",
          backgroundColor: "rgba(71, 204, 214, 0.16)",
        }),
      }}
    >
      <CustomRow>
        <CustomColumn
          style={{
            display: "flex",
            alignItems: "center",
            gap: "0.833rem",
            justifyContent: "flex-start",
          }}
          mb={0.833}
        >
          <IconComponent $isSelected={isSelected} />
          <Text
            style={{
              fontSize: "1.388rem",
              lineHeight: "1.555rem",
              fontWeight: "bold",
            }}
          >
            {name}
          </Text>
        </CustomColumn>
        <CustomColumn>
          <Text
            style={{
              fontSize: "0.972rem",
              color: "#F6F6F6",
              lineHeight: "1.263rem",
            }}
          >
            {description}
          </Text>
        </CustomColumn>
      </CustomRow>
    </SelectionContainer>
  );
};

UserAndAdminContainer.propTypes = {
  isSelected: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  IconComponent: PropTypes.elementType.isRequired,
};

export const InviteNewUserModal = ({ isOpen, onClose, refetchUserRoles }) => {
  const { showMessage } = useContext(SnackbarContext);

  const [isApiLoading, setApiLoading] = useState(false);
  const [isUser, setUser] = useState(true);
  const [fullName, setFullName] = useState("");
  const [emailId, setEmailId] = useState("");

  const handleInviteClick = async () => {
    setApiLoading(true);
    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/inviteNewUser",
        {
          emailId,
          fullName,
          role: isUser ? "USER" : "ADMIN",
        },
      );
      showMessage("Invitation sent successfully", "success");
      refetchUserRoles();
      onClose();
    } catch (err) {
      console.log("Error: inviteNewUser", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Failed to invite the user, please try again later");
      }
      setApiLoading(false);
    }
  };

  return (
    <GenericModal
      title={"Add a member to your Organization"}
      show={isOpen}
      onClose={onClose}
      disableCloseOnOutsideClick={isApiLoading}
      maxHeight="70vh"
    >
      <CustomRow>
        <CustomColumn mb={1.666}>
          <Text
            style={{ opacity: 0.7, color: "#ECECEC", letterSpacing: "0.5px" }}
          >
            Please add the details of the new member to invite them to this
            organisation. You can assign them to a workspace once they accept
            your invite.
          </Text>
        </CustomColumn>
        <ColumnWithFlexWrap mb={1.666}>
          <CustomColumn>
            <InputFieldContainer>
              <InputField
                placeholder="Full Name"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                readOnly={isApiLoading}
              />
            </InputFieldContainer>
          </CustomColumn>
          <CustomColumn>
            <InputFieldContainer>
              <InputField
                placeholder="Email Address"
                value={emailId}
                onChange={(e) => setEmailId(e.target.value.toLocaleLowerCase())}
                readOnly={isApiLoading}
              />
            </InputFieldContainer>
          </CustomColumn>
        </ColumnWithFlexWrap>
        <ColumnWithFlexWrap mb={1.111}>
          <CustomColumn>
            <UserAndAdminContainer
              isSelected={Boolean(isUser)}
              name={"User"}
              handleClick={() => setUser(true)}
              description={
                "Users can access all workspace features based on permissions but can't manage its lifecycle"
              }
              IconComponent={StyledUserIcon}
            />
          </CustomColumn>
          <CustomColumn>
            <UserAndAdminContainer
              isSelected={!isUser}
              name={"Admin"}
              handleClick={() => setUser(false)}
              description={
                "Admins can manage users, workspace features, and lifecycle based on their permissions"
              }
              IconComponent={StyledAdminIcon}
            />
          </CustomColumn>
        </ColumnWithFlexWrap>
        <CustomColumn
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "0.555rem",
          }}
          mb={2.083}
        >
          <StyledWarningIcon />
          <Text
            style={{
              fontSize: "0.833rem",
              fontStyle: "italic",
              lineHeight: "1.083rem",
              color: "#EDB62E",
            }}
          >
            We'll email them the instructions and a magic link to sign in.
          </Text>
        </CustomColumn>
        <CustomColumn
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <StyledButtonsecondary
            width="13.888rem"
            style={{ marginRight: "1.111rem" }}
            onClick={onClose}
            disabled={isApiLoading}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledButtonPrimary
            width="13.888rem"
            onClick={() => handleInviteClick()}
            disabled={!(fullName.length && emailId.length) || isApiLoading}
          >
            Invite Member
          </StyledButtonPrimary>
        </CustomColumn>
      </CustomRow>
    </GenericModal>
  );
};

InviteNewUserModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refetchUserRoles: PropTypes.func.isRequired,
};
