import React, { useMemo, useState } from "react";
import styled from "styled-components";
import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  size,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import PropTypes from "prop-types";

import { remToPx } from "../../utils";
import { ReactComponent as SelectOpenIcon } from "../../assets/images/select_open_icon.svg";
import { ReactComponent as SelectCloseIcon } from "../../assets/images/select_close_icon.svg";
import { ReactComponent as SearchIcon } from "../../assets/images/search_icon.svg";
import { ReactComponent as CheckedIcon } from "../../assets/images/checkbox_checked.svg";
import { ReactComponent as UnCheckedIcon } from "../../assets/images/checkbox_unchecked.svg";

const StyledSelectOpenIcon = styled(SelectOpenIcon)`
  height: 1.111rem;
  width: 1.111rem;
`;
const StyledSelectCloseIcon = styled(SelectCloseIcon)`
  height: 1.111rem;
  width: 1.111rem;
`;
const StyledSearchIcon = styled(SearchIcon)`
  height: 1.388rem;
  width: 1.388rem;
`;
const StyledCheckedIcon = styled(CheckedIcon)`
  height: 1.388rem;
  width: 1.388rem;
`;
const StyledUnCheckedIcon = styled(UnCheckedIcon)`
  height: 1.388rem;
  width: 1.388rem;
  path {
    stroke: #777777;
  }
`;

const SelectInputContainer = styled.div`
  padding: 1.041rem;
  border-radius: 0.555rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  gap: 0.555rem;
  font-size: 0.972rem;
  line-height: 1.111rem;
  border: 0.069rem solid ${({ $borderColor }) => $borderColor};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  color: ${({ $color }) => $color};
`;

const SelectionOptionsContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: flex-start;
  border-radius: 0.555rem;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border: 0.069rem solid ${({ $borderColor }) => $borderColor};
  max-height: ${({ $maxHeight }) => $maxHeight};
`;

const SearchInputContainer = styled.div`
  padding: 0.833rem 0.833rem 0.763rem 0.833rem;
  border-bottom: 0.069rem solid #777777;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.555rem;
  width: 100%;
`;

const SearchInput = styled.input`
  font-size: 1.111rem;
  line-height: 1.388rem;
  color: #cccccc;
  outline: none;
  border: none;
  background-color: inherit;
  ::placeholder {
    color: #777777;
  }
`;

const MultiSelectOptionContainer = styled.div`
  padding: 0.555rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
`;

const MultiSelectOption = styled.div`
  border-radius: 0.555rem;
  padding: 0.694rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.833rem;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #cccccc;
  cursor: pointer;
  &:hover {
    background-color: #4b4b4b;
  }
`;

const NoOptionsText = styled.p`
  margin: 0;
  font-size: 1.111rem;
  color: #f6f6f6;
`;

/**
 * A generic multi-select component with search functionality.
 *
 * @param {string} placeholder - The placeholder text to show when no items are selected.
 * @param {string} selectionCountLabel - The label to use when showing the count of selected items.
 * @param {string} [placeholderColor="#ECECEC"] The color of the placeholder text.
 * @param {string} [selectCountLabelColor="#F6F6F6"] The color of the selection count label.
 * @param {string} [selectionContainerBgColor="#2F2F2F"] The background color of the selection container.
 * @param {string} [optionContainerBgColor="#2F2F2F"] The background color of the options container.
 * @param {string} [optionContainerBorderColor="#F6F6F6"] The border color of the options container.
 * @param {string} [selectionContainerBorderColor="#F6F6F6"] The border color of the selection container.
 * @param {string} [optionsContainerMaxHeight="18rem"] The maximum height of the options container. If the values are more, scroller will be added automatically
 * @param {any[]} [values=[]] The current values of the selected items.
 * @param {any[]} [options=[]] The options to display in the select.
 * @param {(option: any, isSelected: boolean) => void} onChangeFunction - The function to call when an option is selected or deselected. isSelected denotes the state of the option at the time of clicking.
 * @param {(option: any) => string} nameFunction - The function to call to get the name of each option.
 * @param {(option: any) => boolean} isOptionSelectedFunction - The function to call to check if an option is selected.
 * @param {boolean} [isSearchable=false] Whether or not to show a search input. If true, searchPlaceHolder and filterOptionsFunction needs to be passed.
 * @param {string} searchPlaceHolder - The placeholder text of the search input.
 * @param {(options: any[], filterText: string) => any[]} [filterOptionsFunction] The function to call to filter the options based on the search text. Receives the options and the filter text as arguments and returns the filtered options.
 * @param {boolean} [isDisabled=false] Whether or not the component is disabled.
 *
 * @returns {React.ReactElement} The JSX element to render.
 */
const GenericMultiSelectWithSearch = ({
  placeholder,
  selectionCountLabel,
  placeholderColor = "#ECECEC",
  selectCountLabelColor = "#F6F6F6",
  selectionContainerBgColor = "#2F2F2F",
  optionContainerBgColor = "#2F2F2F",
  optionContainerBorderColor = "#F6F6F6",
  selectionContainerBorderColor = "#F6F6F6",
  optionsContainerMaxHeight = "18rem",
  values = [],
  options = [],
  onChangeFunction,
  nameFunction,
  isOptionSelectedFunction,
  isSearchable,
  searchPlaceHolder,
  filterOptionsFunction,
  isDisabled = false,
}) => {
  const [open, setOpen] = useState(false);
  const [filterText, setFilterText] = useState("");

  // Memoized value of filteredOptions to filter the options based on filter text
  const filteredOptions = useMemo(() => {
    if (filterOptionsFunction && filterText) {
      return filterOptionsFunction(options, filterText);
    } else {
      return options;
    }
  }, [filterOptionsFunction, filterText, options]);
  // Memoized value of isSelectedIndexMapper to check if an option is selected
  const isSelectedIndexMapper = useMemo(() => {
    const tempMap = new Map();
    filteredOptions.forEach((option) => {
      const key = option.id || option.value || nameFunction(option);
      tempMap.set(key, isOptionSelectedFunction(option));
    });
    return tempMap;
  }, [filteredOptions, isOptionSelectedFunction, nameFunction]);

  const { refs, floatingStyles, context } = useFloating({
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(remToPx(0.277)),
      flip(),
      shift(),
      size({
        apply({ rects, elements }) {
          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
          });
        },
      }),
    ],
    whileElementsMounted: autoUpdate,
    placement: "bottom-end",
  });
  const click = useClick(context, {
    enabled: !isDisabled,
  });
  const dismiss = useDismiss(context);
  const role = useRole(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  return (
    <>
      <SelectInputContainer
        $backgroundColor={selectionContainerBgColor}
        $borderColor={selectionContainerBorderColor}
        $color={values.length > 0 ? selectCountLabelColor : placeholderColor}
        {...getReferenceProps({
          ref: refs.setReference,
        })}
      >
        {values.length > 0
          ? `${values.length} ${selectionCountLabel}`
          : placeholder}
        {open ? <StyledSelectOpenIcon /> : <StyledSelectCloseIcon />}
      </SelectInputContainer>
      {open && !isDisabled && (
        <FloatingPortal>
          <SelectionOptionsContainer
            $borderColor={optionContainerBorderColor}
            $backgroundColor={optionContainerBgColor}
            $maxHeight={optionsContainerMaxHeight}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            {isSearchable && (
              <SearchInputContainer>
                <StyledSearchIcon />
                <SearchInput
                  placeholder={searchPlaceHolder}
                  value={filterText}
                  onChange={(e) => setFilterText(e.target.value)}
                />
              </SearchInputContainer>
            )}
            <MultiSelectOptionContainer>
              {filteredOptions.length > 0 ? (
                filteredOptions.map((option) => (
                  <MultiSelectOption
                    key={option.id || option.value || nameFunction(option)}
                    onClick={() => {
                      const optionKey =
                        option.id || option.value || nameFunction(option);
                      onChangeFunction(
                        option,
                        isSelectedIndexMapper.get(optionKey),
                      );
                    }}
                  >
                    {isSelectedIndexMapper.get(
                      option.id || option.value || nameFunction(option),
                    ) ? (
                      <StyledCheckedIcon />
                    ) : (
                      <StyledUnCheckedIcon />
                    )}
                    {nameFunction(option)}
                  </MultiSelectOption>
                ))
              ) : (
                <NoOptionsText>No options Found</NoOptionsText>
              )}
            </MultiSelectOptionContainer>
          </SelectionOptionsContainer>
        </FloatingPortal>
      )}
    </>
  );
};

GenericMultiSelectWithSearch.propTypes = {
  placeholder: PropTypes.string.isRequired,
  selectionCountLabel: PropTypes.string.isRequired,
  onChangeFunction: PropTypes.func.isRequired,
  nameFunction: PropTypes.func.isRequired,
  isOptionSelectedFunction: PropTypes.func.isRequired,

  placeholderColor: PropTypes.string,
  selectCountLabelColor: PropTypes.string,
  selectionContainerBgColor: PropTypes.string,
  optionContainerBgColor: PropTypes.string,
  optionContainerBorderColor: PropTypes.string,
  selectionContainerBorderColor: PropTypes.string,
  optionsContainerMaxHeight: PropTypes.string,
  values: PropTypes.array,
  options: PropTypes.array,
  isDisabled: PropTypes.bool,

  isSearchable: PropTypes.bool,
  searchPlaceHolder: function (props, propName, componentName) {
    if (props.isSearchable && !props[propName]) {
      return new Error(
        `${propName} is required in ${componentName} when isSearchable is true.`,
      );
    }
  },
  filterOptionsFunction: function (props, propName, componentName) {
    if (props.isSearchable && typeof props[propName] !== "function") {
      return new Error(
        `${propName} must be a function in ${componentName} when isSearchable is true.`,
      );
    }
  },
};

GenericMultiSelectWithSearch.defaultProps = {
  placeholderColor: "#ECECEC",
  selectCountLabelColor: "#F6F6F6",
  selectionContainerBgColor: "#2F2F2F",
  optionContainerBgColor: "#2F2F2F",
  optionContainerBorderColor: "#F6F6F6",
  selectionContainerBorderColor: "#F6F6F6",
  optionsContainerMaxHeight: "18rem",
  values: [],
  options: [],
  isDisabled: false,
  isSearchable: false,
};

export default GenericMultiSelectWithSearch;
