import styled from "styled-components";
import Column from "../../../components/common/_customColumn";
import CustomRow from "../../../components/common/_customRow";
import GenericModal from "../../../components/common/_genericModal";
import ShowPasswordIcon from "../../../assets/images/show_password_icon.svg";
import DontShowPasswordIcon from "../../../assets/images/dont_show_password_icon.svg";
import { useContext, useState } from "react";
import {
  StyledButtonPrimary,
  StyledButtonsecondary,
} from "../../../components/common/_buttonNewOne";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";
import axiosAdapter from "../../../utils";
import { env } from "../../../env";
import { AuthContext } from "../../../context/AuthContext";
import PropTypes from "prop-types";

const PASSWORD_REGEX =
  /^(?!.*\\)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()\-_+=[\]{}|;:,.<>?/~]).{8,32}$/;

const Text = styled.p`
  font-family: "Articulat CF Medium";
  color: #f6f6f6;
  font-size: 1.25rem;
  line-height: 1.555rem;
  margin: 0;
`;

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const InputField = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 1.111rem;
  line-height: 1.944rem;
  font-weight: 500;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  caret-color: white;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 0.625rem 1.111rem;
  width: 100%;
  border-radius: 0.555rem;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.069rem solid #b0b0b0;
`;

export const ChangePasswordModal = ({ isOpen, onClose }) => {
  const { showMessage } = useContext(SnackbarContext);
  const { logout } = useContext(AuthContext);

  const [oldPassword, setOldPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState("");

  const [newPassword, setNewPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [newPasswordError, setNewPasswordError] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [isApiLoading, setApiLoading] = useState(false);

  const handleChangePasswordClick = async () => {
    setApiLoading(true);
    setOldPasswordError(false);
    setNewPasswordError(false);
    setConfirmPasswordError(false);

    if (!oldPassword) {
      setApiLoading(false);
      showMessage("Please enter old password");
      setOldPasswordError(true);
      return;
    }

    if (!PASSWORD_REGEX.test(oldPassword)) {
      setApiLoading(false);
      showMessage("Please enter valid old password");
      setOldPasswordError(true);
      return;
    }

    if (!newPassword) {
      setApiLoading(false);
      showMessage("Please enter new password");
      setNewPasswordError(true);
      return;
    }

    if (!confirmPassword) {
      setApiLoading(false);
      showMessage("Please enter confirm password");
      setConfirmPasswordError(true);
      return;
    }

    if (!PASSWORD_REGEX.test(newPassword)) {
      setApiLoading(false);
      showMessage("Please enter valid new password");
      setNewPasswordError(true);
      return;
    }

    if (newPassword !== confirmPassword) {
      setApiLoading(false);
      showMessage("New Password and Confirmation do not match");
      setConfirmPasswordError(true);
      return;
    }

    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "user-management/changePassword",
        {
          oldPassword,
          newPassword,
        },
      );
      setApiLoading(false);
      showMessage("Password changed successfully", "success");
      onClose();
      logout();
    } catch (err) {
      console.log("Error: changePassword", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Failed to change password, please try again later");
      }
      setOldPasswordError(true);
      setNewPasswordError(true);
      setConfirmPasswordError(true);
      setApiLoading(false);
    }
  };

  return (
    <GenericModal title="Change Password" show={isOpen} onClose={onClose}>
      <CustomRow>
        <CustomColumn mb={0.833}>
          <Text>
            You're changing your password. Please enter your current password to
            continue.
          </Text>
        </CustomColumn>
        <CustomColumn mb={2.222}>
          <InputFieldContainer
            style={{ ...(oldPasswordError && { borderColor: "#EA3A3A" }) }}
          >
            <InputField
              placeholder="Enter Old Password"
              onChange={(e) => setOldPassword(e.target.value)}
              value={oldPassword}
              type={showOldPassword ? "text" : "password"}
            />
            <button
              onClick={() => setShowOldPassword(!showOldPassword)}
              style={{
                background: "none",
                border: "none",
                padding: 0,
                cursor: "pointer",
              }}
              aria-label="Toggle password visibility"
            >
              <img
                src={showOldPassword ? DontShowPasswordIcon : ShowPasswordIcon}
                alt="Show Password"
                style={{
                  marginLeft: "0.347rem",
                  height: "1.250rem",
                  width: "1.250rem",
                }}
              />
            </button>
          </InputFieldContainer>
        </CustomColumn>
        <CustomColumn mb={0.833}>
          <Text>
            {`Password must have a minimum of 8 characters, with one uppercase, one lowercase, one number, & one special character`}
          </Text>
        </CustomColumn>
        <CustomColumn mb={0.833}>
          <InputFieldContainer
            style={{ ...(newPasswordError && { borderColor: "#EA3A3A" }) }}
          >
            <InputField
              placeholder="Enter New Password"
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
              type={showNewPassword ? "text" : "password"}
            />
            <button
              onClick={() => setShowNewPassword(!showNewPassword)}
              style={{
                background: "none",
                border: "none",
                padding: 0,
                cursor: "pointer",
              }}
              aria-label="Toggle new password visibility"
            >
              <img
                src={showNewPassword ? DontShowPasswordIcon : ShowPasswordIcon}
                alt="Show Password"
                style={{
                  marginLeft: "0.347rem",
                  height: "1.250rem",
                  width: "1.250rem",
                }}
              />
            </button>
          </InputFieldContainer>
        </CustomColumn>
        <CustomColumn mb={2.222}>
          <InputFieldContainer
            style={{ ...(confirmPasswordError && { borderColor: "#EA3A3A" }) }}
          >
            <InputField
              placeholder="Confirm New Password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              type={showConfirmPassword ? "text" : "password"}
            />
            <button
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              tabIndex={0}
              style={{
                cursor: "pointer",
                marginLeft: "0.347rem",
                height: "1.250rem",
                width: "1.250rem",
                background: "none",
                border: "none",
                padding: 0,
              }}
              aria-pressed={showConfirmPassword}
              aria-label="Toggle confirm password visibility"
            >
              <img
                src={
                  showConfirmPassword ? DontShowPasswordIcon : ShowPasswordIcon
                }
                alt={showConfirmPassword ? "Hide Password" : "Show Password"}
              />
            </button>
          </InputFieldContainer>
        </CustomColumn>
        <CustomColumn
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <StyledButtonsecondary
            width="13.888rem"
            style={{ marginRight: "1.111rem" }}
            onClick={onClose}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledButtonPrimary
            width="13.888rem"
            onClick={() => handleChangePasswordClick()}
            disabled={
              oldPassword.length === 0 ||
              newPassword.length === 0 ||
              confirmPassword.length === 0 ||
              isApiLoading
            }
          >
            Change Password
          </StyledButtonPrimary>
        </CustomColumn>
      </CustomRow>
    </GenericModal>
  );
};
ChangePasswordModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
