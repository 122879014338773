import { useContext, useState } from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { AuthContext } from "../../../context/AuthContext";
import GenericModal from "../../../components/common/_genericModal";
import Text from "../../../components/common/_text";
import styled from "styled-components";
import CustomRow from "../../../components/common/_customRow";
import Column from "../../../components/common/_customColumn";
import {
  StyledButtonsecondary,
  StyledPrimaryButton2,
} from "../../../components/common/_buttonNewOne";
import { SnackbarContext } from "../../../layouts/Context/snackBarContext";
import axiosAdapter from "../../../utils";
import { env } from "../../../env";
import { CreateEditWorkspaceModalContext } from "../../../context/CreateEditWorkspaceModalContext";

const CustomListItem = styled.li`
  ::marker {
    color: #b0b0b0;
    font-size: 0.5rem;
  }
`;

const CustomColumn = styled(Column)`
  margin-bottom: ${({ mb }) => (mb ? `${mb}rem` : "0rem")};
`;

const InputField = styled.input`
  font-family: "Articulat CF Medium";
  font-size: 1.111rem;
  line-height: 1.944rem;
  font-weight: 500;
  color: #f6f6f6;
  background-color: inherit;
  outline: none;
  border: none;
  width: 100%;
  caret-color: white;
  &::placeholder {
    color: #777777;
  }
`;

const InputFieldContainer = styled.div`
  padding: 0.625rem 1.111rem;
  width: 100%;
  border-radius: 0.555rem;
  border: none;
  background-color: #2f2f2f;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.069rem solid #b0b0b0;
`;

const DeleteWorkspaceConfirmationModal = ({
  isOpen,
  onClose,
  refetchWorkspaces,
  dataRow,
}) => {
  const { username, setSelectedWorkspaceData } = useContext(AuthContext);
  const { showMessage } = useContext(SnackbarContext);
  const { refetchWorkspacesMap } = useContext(CreateEditWorkspaceModalContext);

  const [confirmationText, setConfirmationText] = useState("");
  const [isApiLoading, setApiLoading] = useState(false);

  const handleDeleteClick = async () => {
    const selectedWorkspaceId = parseInt(
      localStorage.getItem("selectedWorkspaceId") || 0,
    );
    setApiLoading(true);
    try {
      await axiosAdapter(
        "POST",
        env.REACT_APP_URL + "pipeline/deleteWorkspace",
        {
          workspaceId: dataRow.id,
        },
      );
      showMessage("Workspace deleted successfully", "success");
      if (selectedWorkspaceId && selectedWorkspaceId === dataRow.id) {
        setSelectedWorkspaceData(null);
      }
      refetchWorkspaces();
      if (refetchWorkspacesMap?.current) {
        refetchWorkspacesMap.current.forEach((refetch) => {
          refetch();
        });
      }
      onClose();
    } catch (err) {
      console.log("Error: deleteWorkspace", err);
      if (err.response?.data?.remarks) {
        showMessage(err.response.data.remarks);
      } else {
        showMessage("Failed to delete the workspace, please try again later");
      }
      setApiLoading(false);
    }
  };

  return (
    <GenericModal
      title={`Are you sure you want to delete this ${dataRow.workspaceName}?`}
      onClose={onClose}
      show={isOpen}
      disableCloseOnOutsideClick={isApiLoading}
      maxHeight={"70vh"}
    >
      <CustomRow>
        <CustomColumn>
          <Text
            $color={"#B0B0B0"}
            $fontSize={"1.25rem"}
            style={{ letterSpacing: "0.5px" }}
          >
            Please be certain about your decision. Deleting this workspace will
            delete the following and it is not recoverable:
          </Text>
        </CustomColumn>
        <CustomColumn mb={2.77}>
          <ul style={{ marginTop: "1rem" }}>
            <CustomListItem>
              <Text
                $color={"#B0B0B0"}
                $fontSize={"1.25rem"}
                style={{ letterSpacing: "0.5px" }}
              >
                All the Applications created in this workspace for all the
                users.
              </Text>
            </CustomListItem>
            <CustomListItem>
              <Text
                $color={"#B0B0B0"}
                $fontSize={"1.25rem"}
                style={{ letterSpacing: "0.5px" }}
              >
                All the Custom Connectors that are published in the Workspace.
              </Text>
            </CustomListItem>
            <CustomListItem>
              <Text
                $color={"#B0B0B0"}
                $fontSize={"1.25rem"}
                style={{ letterSpacing: "0.5px" }}
              >
                All the deployments that are present in the workspace.
              </Text>
            </CustomListItem>
          </ul>
        </CustomColumn>
        <CustomColumn mb={1.666}>
          <Text
            $fontWeight={"bold"}
            $fontSize={"1.666rem"}
            $lineHeight={1}
          >{`Type '${username}' to confirm`}</Text>
        </CustomColumn>
        <CustomColumn mb={2.777}>
          <InputFieldContainer>
            <InputField
              placeholder="Enter the above confirmation text"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
              readOnly={isApiLoading}
            />
          </InputFieldContainer>
        </CustomColumn>
        <CustomColumn
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <StyledButtonsecondary
            width="13.888rem"
            style={{ marginRight: "1.111rem" }}
            onClick={onClose}
            disabled={isApiLoading}
          >
            Cancel
          </StyledButtonsecondary>
          <StyledPrimaryButton2
            width="13.888rem"
            onClick={() => handleDeleteClick()}
            disabled={isApiLoading || confirmationText !== username}
          >
            Delete Workspace
          </StyledPrimaryButton2>
        </CustomColumn>
      </CustomRow>
    </GenericModal>
  );
};

DeleteWorkspaceConfirmationModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  refetchWorkspaces: PropTypes.func.isRequired,
  dataRow: PropTypes.shape({
    id: PropTypes.number.isRequired,
    workspaceName: PropTypes.string.isRequired,
  }).isRequired,
};

export default DeleteWorkspaceConfirmationModal;
