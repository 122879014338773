import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import styled from "styled-components";
import EmptyState from "../../assets/images/C2D Illustration.svg";
import { StyledButtonPrimary } from "../common/_buttonNewOne";
import Search from "../../assets/images/searchIcon.svg";
import Column from "../common/_customColumn";
import CampaignModal from "./CampaignModal";
import axiosAdapter from "../../utils";
import DeleteIcon from "../../assets/images/delete.svg";
import PaginationContainer from "../common/_paginationContainer";
import GenericDeleteModal from "../common/_genericdeleteModal";
import { SnackbarContext } from "../../layouts/Context/snackBarContext";
import useDebouncedCallback from "../../hooks/useDebouncedCallback";
import CommandPage from "./CommandPage";
import Emptystate from "../../assets/images/searchemptystate.svg";
import C2dGrid from "../../assets/images/Grid.png";
import { env } from "../../env";
import GenericProgressBar from "../common/_genericProgressBar";
import Cancel from "../../assets/images/bulkCancel.svg";
import Delete from "../../assets/images/card_delete_icon.svg";

const CustomRow = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 0.069rem solid #4b4b4b;
  padding: 1.112rem 1.25rem;
  justify-content: space-between;

  & > div {
    align-content: center;
  }
`;

const Content = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 1.67rem;
  line-height: 2.167rem;
  letter-spacing: 0.5;
  color: #cccccc;
  width: 30.34rem;
`;

const Title = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 2.77rem;
  font-weight: bold;
  line-height: 2.88rem;
  color: #f6f6f6;
`;

const StyledButtonsecondary = styled.button`
  all: unset;
  width: 13.6rem;
  color: #f6f6f6;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  font-family: "Articulat CF Medium";
  letter-spacing: 0.035rem;
  font-weight: bold;
  font-size: 0.972rem;
  border-radius: 0.55rem;
  height: 2.77rem;
  text-align: center;
  border: 0.1rem solid ${(props) => (props.disabled ? "#797979" : "#FAFAFA")};
  &:hover {
    background: transparent;
    border: 0.1rem solid #47ccd6;
    color: #47ccd6;
  }
  &:active {
    background: #1e565a;
    box-shadow: 0px 0.55rem 1.112rem 0px rgba(0, 0, 0, 0.3) inset;
  }
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const EmptyContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-image: url(${() => C2dGrid});
`;

const GetStarted = styled.div`
  padding-left: 11.04rem;
  padding-top: 14.5rem;
`;
const Container = styled.div`
  background-color: #2f2f2f;
  width: 100%;
  margin: 1.112rem 1.112rem 1.38rem 1.112rem;
  padding: 1.112rem;
  padding-top: 1.38rem;
  border-radius: 0.84rem;
`;

const SearchBox = styled.div`
  border: 0.138rem solid #b0b0b0;
  width: 17.222rem;
  height: 2.77rem;
  margin-left: 0.277rem;
  border-radius: 0.55rem;
  padding: 0.48rem;
  display: flex;
  position: relative;
`;

const Text = styled.div`
  font-family: "Articulat CF Medium";
  color: #b0b0b0;
`;

const SearchInput = styled.input`
  color: #cccccc;
  border: none;
  background: transparent;
  margin-left: 0.5rem;
  outline: none;
  width: 100%;
  font-family: "Articulat CF Medium";

  &::placeholder {
    color: #cccccc;
  }
`;

const CellHeader = styled.div`
  width: 100%;
  height: 3.89rem;
  background-color: #4b4b4b;
  border-radius: 0.84rem;
  margin-top: 2.084rem;
  display: flex;
  padding: 1.25rem;
  justify-content: space-between;
`;

const DataContainer = styled.div`
  overflow-y: scroll;
  height: 23.39rem;
`;
const CheckboxContainer = styled.label`
  position: relative;
  width: 1.112rem;
  height: 1.112rem;
  cursor: pointer;
`;

const CheckboxInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: #47ccd6; /* Background color when checked */
    border-color: #47ccd6;
  }

  &:checked + span::after {
    content: "";
    position: absolute;
    left: 0.55rem;
    top: 0.277rem;
    width: 0.416rem;
    height: 0.83rem;
    border: solid black; /* Black checkmark */
    border-width: 0 0.138rem 0.138rem 0;
    transform: rotate(45deg);
  }
`;

const CheckboxMark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 1.66rem;
  height: 1.66rem;
  border: 0.104rem solid #cccccc;
  border-radius: 0.138rem;
  transition:
    background-color 0.2s,
    border-color 0.2s;
`;

const BulkDeletePopup = styled.div`
  background-color: var(--black-1, #0d0d0d);
  width: 23.33rem;
  height: 3.33rem;
  border-radius: 0.83rem;
  border: 0.069rem solid var(--black-4, #5e5e5e);
  box-shadow: 0.277rem 0.277rem 0.486rem #0000001f;
  position: absolute;
  z-index: 1000;
  bottom: 2rem;
  left: 37%;
  display: flex;
`;

const CountContainer = styled.div`
  border-right: 1px solid #5e5e5e;
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CountText = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 1.112rem;
  color: #47ccd6;
  padding-left: 0.83rem;
`;

const DeleteText = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 1.112rem;
  color: #f6f6f6;
  cursor: pointer;
  padding-left: 0.83rem;
`;

const DeleteImage = styled.img`
  height: 1.38rem;
  width: 1.38rem;
`;

const CancelImage = styled.img`
  height: 1.12rem;
  width: 1.12rem;
  cursor: pointer;
  &:hover {
    filter: brightness(0) saturate(100%) invert(31%) sepia(89%) saturate(5088%)
      hue-rotate(335deg) brightness(92%) contrast(97%);
  }
`;

const DeleteCampaign = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  cursor: pointer;
  &:hover ${DeleteImage} {
    filter: brightness(0) saturate(100%) invert(31%) sepia(89%) saturate(5088%)
      hue-rotate(335deg) brightness(92%) contrast(97%);
  }

  &:hover ${DeleteText} {
    color: #ea3a3a;
  }
`;

const formatDate = (epoch) => {
  const date = new Date(epoch * 1000);
  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("en-GB", { month: "short" });
  const year = date.getFullYear();
  return `${day} ${month}, ${year}`;
};
function CampaignPageContainer({ deviceName }) {
  const [campaigns, setCampaigns] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isBulkDeleteModalOpen, setIsBulkDeleteModalOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedCommand, setSelectedCommand] = useState(null);
  const { showMessage } = useContext(SnackbarContext);
  const [isEmptyState, setEmptyState] = useState(false);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [isbulkDelete, setIsBulkDelete] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const pageSize = 6;

  const fetchCampaigns = async (page, pageSize = 6, searchKeyword = "") => {
    try {
      const workspaceId = localStorage.getItem("selectedWorkspaceId");
      const response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + `command/getAllCampaign`,
        {
          workspaceId,
          page,
          pageSize,
          searchKeyword,
        },
      );

      if (response?.data?.success) {
        const fetchedCampaigns = response.data.data;
        setTotalCount(response.data.totalCount);
        setCampaigns(fetchedCampaigns || []);
        setEmptyState(false);
      }
    } catch (error) {
      console.log("Error fetching campaigns:", error);
      if (error.response?.data?.status === 404) {
        setEmptyState(true);
        setCampaigns([]);
      }
    }
  };

  const resetpage = () => {
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchCampaigns(currentPage, pageSize, searchTerm);
  }, [currentPage, searchTerm]);

  const debouncedFetchCampaigns = useDebouncedCallback((keyword) => {
    setCurrentPage(1);
    fetchCampaigns(1, pageSize, keyword);
  }, 300);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchCampaigns(pageNumber, pageSize, searchTerm);
  };

  const handleSearch = (event) => {
    const value = event.target.value || "";
    setSearchTerm(value);
    setCurrentPage(1);
    debouncedFetchCampaigns(value);
  };

  const handleSelectAll = useCallback(
    (e) => {
      const isChecked = e.target.checked;
      setSelectAll(isChecked);

      // Create a new object with all campaigns either checked or unchecked
      const newCheckedItems = {};
      campaigns.forEach((campaign) => {
        newCheckedItems[campaign.id] = isChecked;
      });

      setCheckedItems(newCheckedItems);
    },
    [campaigns],
  );

  useEffect(() => {
    setSelectAll(false);
    setCheckedItems({});
  }, [campaigns]);

  const handleCreateCampaign = () => {
    setIsModalOpen(true);
  };

  const handleCampaignClick = (campaign) => {
    setSelectedCommand(campaign);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCommandPageClose = () => {
    setSelectedCommand(null);
  };

  const handleAddCampaign = (newCampaign) => {
    setCampaigns((prevCampaigns) => [...prevCampaigns, newCampaign]);
    setIsModalOpen(false);
  };

  const handleDeleteModal = (campaign) => {
    setSelectedCampaign(campaign);
    setIsDeleteModalOpen(true);
  };

  const handleDelete = async () => {
    if (selectedCampaign) {
      try {
        const result = await axiosAdapter(
          "POST",
          `${env.REACT_APP_URL}command/deleteCampaign`,
          { campaignIds: [selectedCampaign.id] },
        );
        showMessage(result.data.message, "success");
        setIsDeleteModalOpen(false);
        setSelectedCampaign(null);
        fetchCampaigns(currentPage, pageSize);
      } catch (error) {
        showMessage(
          error.message || "An error occurred while deleting the campaign",
          "error",
        );
      }
    }
  };

  const handleBulkDelete = async () => {
    try {
      const result = await axiosAdapter(
        "POST",
        `${env.REACT_APP_URL}command/deleteCampaign`,
        { campaignIds: selectedCampaigns },
      );
      showMessage(result.data.message, "success");
      setIsBulkDeleteModalOpen(false);
      setCheckedItems({});
      fetchCampaigns(currentPage, pageSize);
    } catch (error) {
      showMessage(
        error.message || "An error occurred while deleting campaigns",
        "error",
      );
    }
  };

  const handleCancelBulkDelete = () => {
    setCheckedItems({});
    setIsBulkDelete(false);
    setSelectAll(false);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleCheckboxChange = useCallback((campaignId) => {
    setCheckedItems((prev) => {
      const newCheckedItems = {
        ...prev,
        [campaignId]: !prev[campaignId],
      };

      return newCheckedItems;
    });
  }, []);

  const handleBulkDeleteClick = () => {
    setIsBulkDeleteModalOpen(true);
  };

  const handleCloseBulkDeleteModal = () => {
    setIsBulkDeleteModalOpen(false);
  };

  const processSelectedCampaigns = useMemo(() => {
    const checkedCampaigns = Object.keys(checkedItems)
      .filter((key) => checkedItems[key])
      .map((id) => parseInt(id, 10));

    setSelectedCampaigns(checkedCampaigns);

    const shouldEnableBulkDelete = checkedCampaigns.length > 1;
    setIsBulkDelete(shouldEnableBulkDelete);

    return {
      checkedCampaigns,
      checkedCount: checkedCampaigns.length,
    };
  }, [checkedItems]);

  const isAnyCheckboxChecked = Object.values(checkedItems).some(
    (value) => value === true,
  );

  return (
    <Container>
      {campaigns && campaigns.length === 0 && searchTerm === "" ? (
        <EmptyContainer>
          <img
            src={EmptyState}
            alt="Empty State"
            style={{ height: "22.15rem", marginTop: "6.7rem" }}
          />
          <GetStarted>
            <Title>Get Started</Title>
            <Content style={{ paddingTop: "1.112rem" }}>
              No campaigns added yet. Click the button below to get started!
            </Content>
            <div style={{ paddingTop: "3.194rem" }}>
              <StyledButtonPrimary
                width="18.75rem"
                onClick={handleCreateCampaign}
              >
                Create New Campaign
              </StyledButtonPrimary>
            </div>
          </GetStarted>
        </EmptyContainer>
      ) : (
        <>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <SearchBox>
              <img
                style={{ height: "1.5rem", width: "1.5rem" }}
                src={Search}
                alt=""
              />
              <SearchInput
                type="text"
                placeholder="Search Campaign"
                value={searchTerm}
                onChange={handleSearch}
              />
            </SearchBox>
            <StyledButtonsecondary
              width="13.6rem"
              onClick={handleCreateCampaign}
            >
              Create New Campaign
            </StyledButtonsecondary>
          </div>
          <CellHeader>
            <Column xs={0.6}>
              <CheckboxContainer>
                <CheckboxInput
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
                <CheckboxMark />
              </CheckboxContainer>
            </Column>
            <Column xs={2.2}>
              <Text>Campaign Name</Text>
            </Column>
            <Column xs={1.3}>
              <Text>Created on</Text>
            </Column>
            <Column xs={1.9}>
              <Text>Created By</Text>
            </Column>
            <Column xs={1.9}>
              <Text>Expiry Date</Text>
            </Column>
            <Column xs={2.5}>
              <Text>Campaign Progress</Text>
            </Column>
            <Column xs={0.8}>
              <Text>Actions</Text>
            </Column>
          </CellHeader>
          {isEmptyState ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "cente",
              }}
            >
              <img
                src={Emptystate}
                alt="Empty State"
                style={{ height: "25rem" }}
              />
            </div>
          ) : (
            <>
              <DataContainer>
                {campaigns.map((campaign, index) => (
                  <CustomRow
                    key={campaign.id}
                    style={{
                      height: "3.88rem",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    onClick={() => handleCampaignClick(campaign)}
                  >
                    <Column xs={0.6}>
                      <CheckboxContainer
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <CheckboxInput
                          type="checkbox"
                          checked={checkedItems[campaign.id] || false}
                          onChange={() => handleCheckboxChange(campaign.id)}
                        />
                        <CheckboxMark />
                      </CheckboxContainer>
                    </Column>
                    <Column xs={2.2}>
                      <Text>{campaign.campaignName}</Text>
                    </Column>
                    <Column xs={1.3}>
                      <Text>{formatDate(campaign.createdOn)}</Text>
                    </Column>
                    <Column xs={1.9}>
                      <Text>{campaign.createdBy}</Text>
                    </Column>
                    <Column xs={1.95}>
                      <Text>{formatDate(campaign.expiryDate)}</Text>
                    </Column>
                    <Column xs={2.4}>
                      <GenericProgressBar
                        progressText={"Complete"}
                        progressTotal={parseInt(campaign.totalCount)}
                        progressCount={parseInt(campaign.executedCount)}
                        showProgressCount
                      />
                    </Column>
                    <Column xs={0.8}>
                      <button
                        type="button"
                        style={{
                          background: "none",
                          border: "none",
                          padding: 0,
                          cursor: isAnyCheckboxChecked
                            ? "not-allowed"
                            : "pointer",
                          height: "1.67rem",
                          width: "1.67rem",
                          opacity: isAnyCheckboxChecked ? 0.5 : 1,
                        }}
                        aria-label="Delete campaign"
                        disabled={isAnyCheckboxChecked}
                        onMouseEnter={() => setHoveredIndex(index)}
                        onMouseLeave={() => setHoveredIndex(null)}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleDeleteModal(campaign);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter" || e.key === " ") {
                            e.stopPropagation();
                            handleDeleteModal(campaign);
                          }
                        }}
                      >
                        <img
                          src={DeleteIcon}
                          alt=""
                          style={{
                            height: "1.67rem",
                            width: "1.67rem",
                            filter:
                              hoveredIndex === index && !isAnyCheckboxChecked
                                ? "brightness(0) saturate(100%) invert(31%) sepia(89%) saturate(5088%) hue-rotate(335deg) brightness(92%) contrast(97%)"
                                : "none",
                          }}
                        />
                      </button>
                    </Column>
                  </CustomRow>
                ))}
              </DataContainer>
              <div style={{ paddingTop: "0.64rem", paddingBottom: "0.64rem" }}>
                <PaginationContainer
                  totalCount={totalCount}
                  currentPage={currentPage}
                  onPageChange={handlePageChange}
                  pageSize={pageSize}
                />
              </div>
            </>
          )}
        </>
      )}
      {isModalOpen && (
        <CampaignModal
          isModalOpen={isModalOpen}
          onClose={handleCloseModal}
          onAddCampaign={handleAddCampaign}
          fetchCampaigns={fetchCampaigns}
          resetpage={resetpage}
          deviceName={deviceName}
        />
      )}
      {isDeleteModalOpen && (
        <GenericDeleteModal
          title={`Are you sure you want to delete ${selectedCampaign?.campaignName}?`}
          message={
            "If you delete this campaign, you will have to recreate it to run it again. Please be sure before you proceed."
          }
          deleteButtonText={"Delete Campaign"}
          onDelete={handleDelete}
          isModalOpen={isDeleteModalOpen}
          onClose={handleCloseDeleteModal}
        />
      )}
      {selectedCommand && (
        <CommandPage
          command={selectedCommand}
          onClose={handleCommandPageClose}
        />
      )}
      {isbulkDelete && (
        <BulkDeletePopup>
          <CountContainer>
            <CancelImage
              src={Cancel}
              alt="cancel"
              onClick={handleCancelBulkDelete}
            />
            <CountText>
              {processSelectedCampaigns.checkedCount} Selected
            </CountText>
          </CountContainer>
          <DeleteCampaign>
            <DeleteImage src={Delete} alt="delete icon" />
            <DeleteText onClick={() => handleBulkDeleteClick()}>
              Delete Campaigns
            </DeleteText>
          </DeleteCampaign>
        </BulkDeletePopup>
      )}
      {isBulkDeleteModalOpen && (
        <GenericDeleteModal
          title={`Are you sure you want to delete the Campaigns?`}
          message={`You are deleting ${processSelectedCampaigns.checkedCount} campaigns. If you delete these campaigns, you will have to recreate it to run it again. Please be sure before you proceed.`}
          deleteButtonText={"Delete Campaigns"}
          onDelete={handleBulkDelete}
          isModalOpen={isBulkDeleteModalOpen}
          onClose={handleCloseBulkDeleteModal}
        />
      )}
    </Container>
  );
}

export default CampaignPageContainer;
