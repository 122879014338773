import styled from "styled-components";
import PropTypes from "prop-types";
const headXlargest = styled.div`
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 130%;
  color: ${(props) => props.color};
`;

const headLarge = styled.div`
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-size: 48px;
  line-height: 130%;
  font-weight: bold;
  color: ${(props) => props.color};
`;

const headMedium = styled.div`
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 130%;
  color: ${(props) => props.color};
`;

const headSmall = styled.div`
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 130%;
  color: ${(props) => props.color};
`;

const headSmallX = styled.div`
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 130%;
  color: ${(props) => props.color};
`;

const headXsmall = styled.div`
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 31.2px;
  color: ${(props) => props.color};
`;

const headXXsmall = styled.div`
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 130%;
  color: ${(props) => props.color};
`;

const subHeadLarge = styled.div`
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 130%;
  color: ${(props) => props.color};
`;

const subHeadMedium = styled.div`
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 130%;
  color: ${(props) => props.color};
`;

const subHeadSmall = styled.div`
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 130%;
  color: ${(props) => props.color};
`;

const pLarge = styled.div`
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 130%;
  color: ${(props) => props.color};
`;

const pBoldLarge = styled.div`
  font-family: "Articulat CF Medium";
  font-weight: bold;
  font-size: 1.25rem;
  line-height: 130%;
  color: ${(props) => props.color};
`;

const pMedium = styled.div`
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 1.112rem;
  line-height: 130%;
  color: ${(props) => props.color};
`;

const pBoldMedium = styled.div`
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  color: ${(props) => props.color};
`;

const pSmall = styled.div`
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 0.972rem;
  line-height: 130%;
  color: ${(props) => props.color};
`;

const pSmall2 = styled.div`
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: 300;
  font-size: 0.79rem;
  line-height: 130%;
  color: ${(props) => props.color};
`;

const pBoldSmall = styled.div`
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 130%;
  color: ${(props) => props.color};
`;

const pXsmall = styled.div`
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: ${(props) => props.color};
`;

const pBoldXsmall = styled.div`
  font-family: "Articulat CF Medium";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 130%;
  color: ${(props) => props.color};
`;

const pBoldCmedium = styled.div`
  font-family: Articulat CF Medium;
  font-size: 24px;
  font-weight: bold;
  line-height: 41.6px;
  text-align: left;
`;

const pDescription = styled.div`
  font-family: "Articulat CF Medium";
  min-width: 793px;
  white-space: "normal";
  font-size: 1.67rem;
  font-weight: bold;
  line-height: 31.2px;
  color: ${(props) => props.color};
`;
const filters = styled.div`
  font-family: Articulat CF Medium;
  font-size: 18px;
  font-weight: bold;
  line-height: 41.6px;
  text-align: left;
  color: ${(props) => props.color};
  margin-left: 8px;
`;

const Typography = (props) => {
  let tag = props.tag;
  let StyledTextTag;

  switch (tag) {
    case "head-x-large":
      StyledTextTag = headXlargest;
      break;

    case "head-large":
      StyledTextTag = headLarge;
      break;

    case "head-medium":
      StyledTextTag = headMedium;
      break;

    case "head-small":
      StyledTextTag = headSmall;
      break;

    case "head-x-small":
      StyledTextTag = headXsmall;
      break;

    case "head-x-x-small":
      StyledTextTag = headXXsmall;
      break;

    case "sub-head-large":
      StyledTextTag = subHeadLarge;
      break;

    case "sub-head-medium":
      StyledTextTag = subHeadMedium;
      break;

    case "sub-head-small":
      StyledTextTag = subHeadSmall;
      break;

    case "p-large":
      StyledTextTag = pLarge;
      break;

    case "p-bold-large":
      StyledTextTag = pBoldLarge;
      break;

    case "p-medium":
      StyledTextTag = pMedium;
      break;

    case "p-bold-medium":
      StyledTextTag = pBoldMedium;
      break;

    case "p-small":
      StyledTextTag = pSmall;
      break;

    case "p-small-2":
      StyledTextTag = pSmall2;
      break;

    case "p-bold-small":
      StyledTextTag = pBoldSmall;
      break;

    case "p-x-small":
      StyledTextTag = pXsmall;
      break;

    case "p-bold-x-small":
      StyledTextTag = pBoldXsmall;
      break;

    case "p-bold-c-medium":
      StyledTextTag = pBoldCmedium;
      break;

    case "filter-text":
      StyledTextTag = filters;
      break;

    case "sub-head-x-small":
      StyledTextTag = headSmallX;
      break;

    case "p-description":
      StyledTextTag = pDescription;
      break;

    default:
      break;
  }

  return <StyledTextTag color={props.color}>{props.text}</StyledTextTag>;
};

Typography.propTypes = {
  tag: PropTypes.oneOf([
    "head-x-large",
    "head-large",
    "head-medium",
    "head-small",
    "head-x-small",
    "head-x-x-small",
    "sub-head-large",
    "sub-head-medium",
    "sub-head-small",
    "sub-head-x-small",
    "p-large",
    "p-bold-large",
    "p-medium",
    "p-bold-medium",
    "p-small",
    "p-bold-small",
    "p-x-small",
    "p-bold-x-small",
    "p-bold-c-medium",
    "filter-text",
    "p-description",
  ]).isRequired,
  text: PropTypes.node.isRequired,

  color: PropTypes.string,
};

Typography.defaultProps = {
  color: "#000000",
};

export default Typography;
