import React, {
  createContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import decode from "jwt-decode";
import axiosAdapter, { decryptJwe } from "../utils";
import { env } from "../env";
import PropTypes from "prop-types";

// Create AuthContext
export const AuthContext = createContext({
  userRole: "",
  username: "",
  userEmail: "",
  userFullName: "",
  isTokenPresent: false,
  isAuthInitialized: false,
  loginTime: null,
  expiryTime: null,
  selectedWorkspaceData: null,
  setSelectedWorkspaceData: () => {},
  login: () => {},
  logout: () => {},
});

export const AuthProvider = ({ children }) => {
  const [username, setUsername] = useState("");
  const [userRole, setUserRole] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userFullName, setUserFullName] = useState("");
  const [loginTime, setLoginTime] = useState(null);
  const [expiryTime, setExpiryTime] = useState(null);
  const [isTokenPresent, setIsTokenPresent] = useState(false);
  const [isAuthInitialized, setIsAuthInitialized] = useState(false);
  const [selectedWorkspaceData, setSelectedWorkspaceDataState] = useState(null);

  const setSelectedWorkspaceData = useCallback((selectedWorkspaceData) => {
    if (selectedWorkspaceData) {
      localStorage.setItem("selectedWorkspaceId", selectedWorkspaceData.id);
    } else {
      localStorage.removeItem("selectedWorkspaceId");
    }
    setSelectedWorkspaceDataState(selectedWorkspaceData);
  }, []);

  const clearAuthState = useCallback(() => {
    setUsername("");
    setUserRole("");
    setUserEmail("");
    setUserFullName("");
    setLoginTime(null);
    setExpiryTime(null);
    setIsTokenPresent(false);
    setSelectedWorkspaceData(null);
  }, [setSelectedWorkspaceData]);

  const processToken = useCallback(async (jweToken) => {
    try {
      const jwtToken = await decryptJwe(jweToken);
      const { userRole, username, fullName, emailId, iat, exp } =
        decode(jwtToken);

      setUsername(username);
      setUserRole(userRole);
      setUserEmail(emailId);
      setUserFullName(fullName);
      setLoginTime(iat);
      setExpiryTime(exp);
      setIsTokenPresent(true);

      return true;
    } catch (error) {
      console.error("Failed to process token", error);
      return false;
    }
  }, []);

  const login = useCallback(
    async (jweToken) => {
      localStorage.setItem("token", jweToken);
      await processToken(jweToken);
    },
    [processToken],
  );

  const logout = useCallback(() => {
    axiosAdapter("POST", env.REACT_APP_URL + "user-management/userLogout", {
      data: null,
    }).catch(() => {});

    localStorage.removeItem("token");
    clearAuthState();
  }, [clearAuthState]);

  // Initialize authentication state
  useEffect(() => {
    const initializeAuth = async () => {
      const jweToken = localStorage.getItem("token");

      if (jweToken) {
        const success = await processToken(jweToken);
        if (!success) {
          clearAuthState();
        }
      }

      setIsAuthInitialized(true);
    };

    initializeAuth();
  }, [processToken, clearAuthState]);

  const authValue = useMemo(
    () => ({
      userRole,
      username,
      userEmail,
      userFullName,
      isTokenPresent,
      isAuthInitialized,
      loginTime,
      expiryTime,
      selectedWorkspaceData,
      setSelectedWorkspaceData,
      login,
      logout,
    }),
    [
      userRole,
      username,
      userEmail,
      userFullName,
      isTokenPresent,
      isAuthInitialized,
      loginTime,
      expiryTime,
      selectedWorkspaceData,
      setSelectedWorkspaceData,
      login,
      logout,
    ],
  );

  if (!isAuthInitialized) {
    return null;
  }

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
