import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";

// Styled Components
const StepperContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  flex: 1;
`;

const StepNumberContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StepNumber = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.55rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Articulat CF Medium";
  font-weight: bold;
  margin-right: 0.7rem;
  transition: background-color 0.3s ease;
`;

const StepDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StepText = styled.div`
  font-size: 1.2rem;
  color: #f6f6f6;
  transition: color 0.3s ease;
  font-family: "Articulat CF Medium";
  font-weight: bold;
`;

const StepStatus = styled.div`
  font-size: 0.79rem;
  color: ${(props) => {
    if (props.isActive === "Pending") return "#777777";
    if (props.isActive) return "#00BCD4";
    return "#EA3A3A"; // Fallback to default
  }};
  transition: color 0.3s ease;
  font-family: "Articulat CF Medium";
`;

const progressAnimation = keyframes`
  0% { width: 0; }
  100% { width: 100%; }
`;

const Line = styled.div`
  flex: 0.5;
  height: 0.13rem;
  background-color: #666;
  position: relative;
  margin-right: 1rem;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #00bcd4;
    width: ${(props) => (props.active ? "100%" : "0")};
    animation: ${(props) => (props.animating ? progressAnimation : "none")} 0.5s;
  }
`;

// Main Stepper Component
const DynamicStepper = ({
  steps,
  currentStep,
  setCurrentStep,
  animatingLine,
}) => {
  useEffect(() => {}, [steps]);

  return (
    <StepperContainer>
      {steps.map((step, index) => {
        const isActive = step.active && currentStep > index; // Step completed and active
        return (
          <React.Fragment key={step.id || index}>
            <Step>
              <StepNumberContainer>
                <StepNumber active={isActive}>
                  {step.icon || index + 1}
                </StepNumber>
                <StepDetails>
                  <StepText active={isActive}>{step.name}</StepText>
                  <StepStatus isActive={step.active}>
                    {step.statusText || "Status Not Available"}
                  </StepStatus>
                </StepDetails>
              </StepNumberContainer>
            </Step>
            {index < steps.length - 1 && (
              <Line
                active={currentStep > index + 1}
                animating={animatingLine === index + 1}
              />
            )}
          </React.Fragment>
        );
      })}
    </StepperContainer>
  );
};

// PropTypes Validation
DynamicStepper.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string.isRequired,
      icon: PropTypes.node, // Allows passing custom icons or JSX
    }),
  ).isRequired,
  currentStep: PropTypes.number.isRequired, // Current active step
  setCurrentStep: PropTypes.func.isRequired, // Function to update the current step
  animatingLine: PropTypes.number, // Line animation state
};

// Default Props
DynamicStepper.defaultProps = {
  animatingLine: null,
};

export default DynamicStepper;
