import { React, useState, useEffect } from "react";
import styled from "styled-components";
import LeftArrow from "../../assets/images/left_arrow_icon.svg";
import Search from "../../assets/images/searchIcon.svg";
import Down from "../../assets/images/chevron_down.svg";
import Up from "../../assets/images/dropupfinal.svg"; // Added for toggle icon
import { StyledButtonPrimary } from "../common/_buttonNewOne";
import Column from "../common/_customColumn";
import PaginationContainer from "../common/_paginationContainer";
import axiosAdapter from "../../utils";
import CustomRow from "../common/_customRow";
import useDebouncedCallback from "../../hooks/useDebouncedCallback";
import { env } from "../../env";
import Emptystate from "../../assets/images/searchemptystate.svg";
import * as XLSX from "xlsx";
import PropTypes from "prop-types"; // Import PropTypes
import Tooltip from "../common/_tooltip";

const OverlayContainer = styled.div`
  position: fixed;
  top: 3.83rem;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--black-1, #0d0d0d);
  z-index: 1;
  height: 100%;
  margin: 1.12rem;
  overflow: scroll;
  border-top-right-radius: 0.84rem;
  border-top-left-radius: 0.84rem;
  border: 1px solid var(--grey-1, #777777);
`;

const Title = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 1.67rem;
  font-weight: bold;
  line-height: 41.6px;
  text-align: left;
  color: #f6f6f6;
  padding-left: 1.12rem;
`;

const Container = styled.div`
  background-color: #2f2f2f;
  height: 80%;
  margin-left: 1.112rem;
  border-top-right-radius: 0.84rem;
  border-top-left-radius: 0.84rem;
`;

const CellHeader = styled.div`
  width: 100%;
  height: 3.89rem;
  background-color: #4b4b4b;
  border-radius: 0.84rem;
  margin-top: 2.084rem;
  display: flex;
  align-items: center;
  padding: 1.25rem;
`;

const TopContainer = styled.div`
  padding: 1.112rem 1.112rem 0 1.112rem;
`;

const Text = styled.div`
  color: #b0b0b0;
  font-family: "Articulat CF Medium";
  font-size: 1.2rem;
`;

const DataText = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 1.12rem;
  color: #f6f6f6;
`;

const StatusText = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 1.12rem;
  color: ${({ status }) => {
    switch (status) {
      case "Yet to Execute":
        return "#EDB62E";
      case "Executed":
        return "#259D55";
      case "Executing":
        return "#47ccd6";
      default:
        return "#EA3A3A";
    }
  }};
`;

const StyledRow = styled.div`
  display: flex;
  align-items: center;
`;
const SearchBox = styled.div`
  border: 2px solid #b0b0b0;
  width: 17.222rem;
  height: 2.77rem;
  border-radius: 0.55rem;
  padding: 0.48rem;
  display: flex;
  position: relative;
`;

const FilterBox = styled.div`
  border: 2px solid #b0b0b0;
  width: 10.97rem;
  height: 2.77rem;
  border-radius: 0.55rem;
  padding: 0.48rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  cursor: pointer;
`;

const FilterText = styled.div`
  font-family: "Articulat CF Medium";
  font-size: 0.97rem;
  color: #f6f6f6;
  font-weight: bold;
`;

const FilterDropdown = styled.div`
  position: absolute;
  top: 3.3rem;
  left: 0;
  background-color: #3b3b3b;
  border: 1px solid #b0b0b0;
  border-radius: 0.55rem;
  width: 10.97rem;
  padding: 0.5rem;
  z-index: 10;
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0.4rem 0;
  color: #f6f6f6;
  font-family: "Articulat CF Medium";
  font-size: 0.9rem;
  cursor: pointer;

  input {
    margin-right: 0.5rem;
  }
`;

const SearchInput = styled.input`
  color: #cccccc;
  border: none;
  background: transparent;
  margin-left: 0.5rem;
  outline: none;
  width: 100%;
  font-family: "Articulat CF Medium";

  &::placeholder {
    color: #cccccc;
  }
`;

const DataContainer = styled.div`
  overflow-y: scroll;
  height: 73%;
`;
const Line = styled.div`
  border-bottom: 1px solid #4b4b4b;
`;
const BackIcon = styled.div``;

const formatDate = (epoch) => {
  const date = new Date(epoch * 1000);
  const day = date.getDate();
  const month = date.toLocaleString("en-GB", { month: "short" });
  const year = date.getFullYear();
  let hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;
  return `${day} ${month}' ${year}, ${hours}:${minutes}${ampm}`;
};

const CommandPage = ({ onClose, command }) => {
  console.log("campaign nameeeee", command);
  const [currentPage, setCurrentPage] = useState(1);
  const [commands, setCommands] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [isEmptyState, setEmptyState] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({
    "Yet to Execute": false,
    Executing: false,
    "Timed Out": false,
    Executed: false,
    Failed: false,
  });
  const pageSize = 8;

  const fetchCommands = async (page, size, keyword) => {
    try {
      const activeFilters = Object.keys(selectedFilters).filter(
        (key) => selectedFilters[key],
      );

      const response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + `command/getAllCommands`,
        {
          campaignId: command.id,
          page: page,
          pageSize: size,
          searchKeyword: keyword,
          filter: activeFilters.length > 0 ? activeFilters : null,
        },
      );
      const fetchedCommands = response.data.data;
      setCommands(fetchedCommands);
      setTotalCount(response.data.totalCount);
      setEmptyState(false);
      console.log(response, "response.status");
      if (response && response.status === 0) {
        setCommands([]);
        setTotalCount(0);
        setEmptyState(true);
        console.log(isEmptyState, "isEmptyState");
      }
    } catch (error) {
      console.error("Error fetching commands:", error);
      if (error.response.data.status === 404) {
        setEmptyState(true);
      }
    }
  };

  useEffect(() => {
    fetchCommands(currentPage, pageSize, searchTerm);
  }, [currentPage, command.id, searchTerm, selectedFilters]);

  const handleBackClick = () => {
    if (onClose) {
      onClose();
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const debouncedFetchCommands = useDebouncedCallback((keyword) => {
    setCurrentPage(1);
    fetchCommands(1, pageSize, keyword);
  }, 300);

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    debouncedFetchCommands(value);
  };
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleFilterChange = (filter) => {
    setSelectedFilters((prev) => ({
      ...prev,
      [filter]: !prev[filter],
    }));
    setCurrentPage(1);
  };

  const handleDownload = async () => {
    try {
      console.log("callinggg");
      const response = await axiosAdapter(
        "POST",
        env.REACT_APP_URL + `command/getAllCommands`,
        {
          campaignId: command.id,
          page: 0,
          pageSize: totalCount,
        },
      );
      const fetchedCommands = response.data.data;
      setCommands(fetchedCommands);
      setTotalCount(response.data.totalCount);

      const dataForExcel = fetchedCommands.map((item) => ({
        IMEI: item.imei,
        Command: item.command,
        Status: item.status,
        "Last Updated At": formatDate(item.updatedAt),
        Response: item.response || "Waiting for Response",
      }));

      const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Commands Report");

      XLSX.writeFile(workbook, `${command.campaignName}_${Date.now()}.xlsx`);
    } catch (error) {
      console.error("Error fetching commands:", error);
    }
  };

  return (
    <OverlayContainer>
      <TopContainer>
        <StyledRow>
          <BackIcon onClick={handleBackClick}>
            <img
              src={LeftArrow}
              alt=""
              style={{ width: "2.2rem", height: "2.2rem", cursor: "pointer" }}
            />
          </BackIcon>
          <Title>Here’s the status of the pushed commands</Title>
        </StyledRow>
        <StyledRow
          style={{ justifyContent: "space-between", paddingTop: "1.38rem" }}
        >
          <SearchBox>
            <img
              style={{ height: "1.5rem", width: "1.5rem" }}
              src={Search}
              alt=""
            />
            <SearchInput
              type="text"
              placeholder="Search Details"
              value={searchTerm}
              onChange={handleSearch}
            />
          </SearchBox>
          <StyledRow style={{ gap: "0.97rem" }}>
            <FilterBox onClick={toggleDropdown}>
              <FilterText>Filter By</FilterText>
              <img
                src={dropdownOpen ? Up : Down}
                alt=""
                style={{ width: "1.5rem", height: "1.5rem" }}
              />
              {dropdownOpen && (
                <FilterDropdown>
                  {Object.keys(selectedFilters).map((filter) => (
                    <DropdownItem key={filter}>
                      <input
                        type="checkbox"
                        checked={selectedFilters[filter]}
                        onChange={(e) => {
                          e.stopPropagation();
                          handleFilterChange(filter);
                        }}
                        style={{
                          width: "1.39rem",
                          height: "1.39rem",
                          cursor: "pointer",
                          accentColor: "#47CCD6",
                          opacity: selectedFilters[filter] ? 1 : 0.3,
                          marginRight: "0.8rem",
                          border: "0.3rem solid #777777",
                          borderRadius: "1.5px",
                        }}
                      />
                      {filter}
                    </DropdownItem>
                  ))}
                </FilterDropdown>
              )}
            </FilterBox>
            <StyledButtonPrimary
              onClick={handleDownload}
              style={{ width: "14rem", height: "2.77rem" }}
            >
              Download Report
            </StyledButtonPrimary>
          </StyledRow>
        </StyledRow>
      </TopContainer>
      <Container>
        <CellHeader>
          <Column xs={1.56}>
            <Text>{"//IMEI//"}</Text>
          </Column>
          <Column xs={4.07}>
            <Text>Command</Text>
          </Column>
          <Column xs={1.53}>
            <Text>Status</Text>
          </Column>
          <Column xs={1.8}>
            <Text>Last updated at</Text>
          </Column>
          <Column xs={1}>
            <Text>Response</Text>
          </Column>
        </CellHeader>
        {isEmptyState ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "cente",
            }}
          >
            <img src={Emptystate} alt="Empty State" />
          </div>
        ) : (
          <>
            <DataContainer>
              {commands.map((item, index) => (
                <div key={item.id}>
                  <CustomRow key={item}>
                    <StyledRow
                      key={item}
                      style={{
                        height: "3.5rem",
                        width: "100%",
                        borderBottom: "1px solid #4b4b4b",
                      }}
                    >
                      <Column xs={1.7} style={{ paddingLeft: "1.12rem" }}>
                        <DataText>{item.imei}</DataText>
                      </Column>
                      <Column xs={3.95}>
                        <Tooltip title={item.command}>
                          <DataText
                            style={{
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              width: "96%",
                            }}
                          >
                            {item.command}
                          </DataText>
                        </Tooltip>
                      </Column>
                      <Column xs={1.5}>
                        <StatusText status={item.status}>
                          {item.status || "Yet to Execute"}
                        </StatusText>
                      </Column>
                      <Column xs={1.75}>
                        <DataText>{formatDate(item.updatedAt)}</DataText>
                      </Column>
                      <Column xs={3.1}>
                        <DataText>{item.response}</DataText>
                      </Column>
                    </StyledRow>
                  </CustomRow>
                  {index !== commands.length - 1 && <Line />}
                </div>
              ))}
            </DataContainer>
            <div style={{ marginLeft: "1.12rem", marginRight: "1.12rem" }}>
              <PaginationContainer
                totalCount={totalCount}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                pageSize={pageSize}
              />
            </div>
          </>
        )}
      </Container>
    </OverlayContainer>
  );
};

CommandPage.propTypes = {
  onClose: PropTypes.func.isRequired,
  command: PropTypes.shape({
    id: PropTypes.number.isRequired,
    campaignName: PropTypes.string,
  }).isRequired,
};

export default CommandPage;
