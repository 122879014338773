import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import NavBar from "../components/NavBar/NavBar";
import { SnackbarProvider } from "../layouts/Context/snackBarContext";
import TopSnackBar from "../components/common/_topSnackBar";
import { AuthContext } from "../context/AuthContext";
import SideBar from "../components/SideBar/SideBar";
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";
import CreateEditWorkspaceModalContextProvider from "../context/CreateEditWorkspaceModalContext";

/**
 *
 * @description (AppRoute - Defines Page Routes)
 * @param {*} { component: Component, isAuthProtected, ...rest }
 */
const /**
   *
   *
   * @param {*} props
   * @return {*}
   */
  AppRoute = ({ component: Component, isAuthProtected, ...rest }) => {
    const routes = {
      HOME: "/",
      CONNECTORS_PLAYGROUND: "/connectorsPlayground",
      ON_BOARDING: "/onBoarding",
      CONNECTORS: "/connectors",
      APPLICATIONS: "/applications",
      TERMINAL: "/buildLogs",
      TERMINAL_IFRAME: "/terminal-iframe",
      CONNECTOR_INFORMATION: "/connectorInformation",
      CONNECTOR_CONFIGURATION: "/connectorConfiguration",
      CODE_EDITOR: "/codeEditor",
      OAUTH_AUTHORIZE: "/oauth/authorize",
      CREATE_APPLICATION: "/createApplication",
      ACTIVITY_AUDITOR: "/activityAuditor",
      DEPLOY_CONNECTOR: "/deployConnector",
      // Add more routes here
    };

    const { logout, isTokenPresent, isAuthInitialized, expiryTime } =
      useContext(AuthContext);
    const currentTime = Math.floor(Date.now() / 1000);

    // Don't render anything until auth is initialized
    if (!isAuthInitialized) {
      return null;
    }

    // Handle authentication checks
    if (isAuthProtected) {
      // Check if user is not authenticated
      if (!isTokenPresent) {
        return <Redirect to="/" />;
      }

      // Check if token is expired
      if (expiryTime && currentTime > expiryTime) {
        logout();
        return <Redirect to="/" />;
      }
    } else if (isTokenPresent && expiryTime && currentTime < expiryTime) {
      // Redirect authenticated users away from non-auth routes (like login)
      return <Redirect to="/onBoarding" />;
    }
    // Add the routes where you don't want to render the sidebar
    // Add more routes if needed
    const routeWithoutSkeleton = [routes.TERMINAL_IFRAME];
    const routesWithoutSidebar = [
      routes.TERMINAL,
      routes.ON_BOARDING,
      routes.CONNECTOR_INFORMATION,
      routes.CODE_EDITOR,
      routes.OAUTH_AUTHORIZE,
      routes.CONNECTOR_CONFIGURATION,
      routes.CREATE_APPLICATION,
      routes.ACTIVITY_AUDITOR,
      routes.DEPLOY_CONNECTOR,
    ]; // Add more routes if needed

    // Check if the current route path is in the routesWithoutSidebar array
    const isSideBar = !routesWithoutSidebar.includes(rest.path);
    return (
      <Route
        {...rest}
        render={(props) => {
          if (isAuthProtected) {
            if (routeWithoutSkeleton.includes(rest.path)) {
              return <Component {...props} />;
            }

            return (
              <SnackbarProvider>
                <CreateEditWorkspaceModalContextProvider>
                  <TopSnackBar />
                  <NavBar />
                  <Row noGutters>
                    {isSideBar && (
                      <Col xs={2}>
                        <SideBar />
                      </Col>
                    )}
                    <Col xs={isSideBar ? 10 : 12}>
                      <Component {...props} />
                    </Col>
                  </Row>
                </CreateEditWorkspaceModalContextProvider>
              </SnackbarProvider>
            );
          }

          return (
            <SnackbarProvider>
              <TopSnackBar />
              <Component {...props} />
            </SnackbarProvider>
          );
        }}
      />
    );
  };

AppRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  isAuthProtected: PropTypes.bool,
  rest: PropTypes.object,
};

AppRoute.defaultProps = {
  isAuthProtected: false,
};

export default AppRoute;
